import React, { useContext, useEffect, useState } from 'react'
import { useActions } from '../../Action'
import { SurveyPages } from '../../ActionTypes'
import { store } from '../../Store'
import Button from '../shared/Button'

import Text from '../shared/Text'

import Rating0 from '../../assets/survey-assets/Number1.js'
import Rating1 from '../../assets/survey-assets/Number2.js'
import Rating2 from '../../assets/survey-assets/Number3.js'
import Rating3 from '../../assets/survey-assets/Number4.js'
import Rating4 from '../../assets/survey-assets/Number5.js'
import Rating5 from '../../assets/survey-assets/Number6.js'
import Rating6 from '../../assets/survey-assets/Number7.js'
import Rating7 from '../../assets/survey-assets/Number8.js'
import Rating8 from '../../assets/survey-assets/Number9.js'
import Rating9 from '../../assets/survey-assets/Number10.js'
import Rating10 from '../../assets/survey-assets/Number11.js'
import RangeSlider from '../shared/RangeSlider.js'

const Rating = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { surveyPages } = state
  const { selectedRating, surveyQuestions } = surveyPages
  const imgComponents = { Rating0, Rating1, Rating2, Rating3, Rating9, Rating4, Rating5, Rating6, Rating10, Rating7, Rating8 }
  const componentNames = ['Rating0', 'Rating1', 'Rating2', 'Rating3', 'Rating9', 'Rating4', 'Rating5', 'Rating6', 'Rating10', 'Rating7', 'Rating8']
  const question = surveyQuestions?.find((item) => item.DisplayOrder === '1')

  const getParams = (url) => {
    if (!url) url = window.location.search
    var query = url.substr(1)
    var result = {}
    query.split('&').forEach(function (part) {
      var item = part.split('=')
      result[item[0]] = decodeURIComponent(item[1])
    })
    return result
  }
  useEffect(() => {
    const tradeId = getParams()?.tradeId
    actions.setSurveyTradeId(tradeId)
    const payload = {
      GetSurveyQuestions: {
        SurveyType: 'NPS',
        ClientId: 'A10CCA8AB46B11EB8AC60AA01D6C824E',
        TriggerPoint: 'After_Delivery',
        Language: 'en-US',
        ServiceRequestId: '',
        SessionId: '',
      },
    }
    actions.getSurveyQuestions(payload)
  }, [])

  const getRatingLabel = (rating) => {
    if (rating >= 9) return 'Extremely Likely'
    if (rating >= 7) return 'Likely'
    if (rating >= 5) return 'Neutral'
    if (rating >= 3) return 'Unlikely'
    return 'Not likely at all'
  }

  const getCustomerCategory = (score) => {
    // These are industry standards for NPS.
    if (score > 8) return 'PROMOTER'
    if (score > 6) return 'PASSIVE'
    if (score !== null) return 'DETRACTOR'
    return null
  }
  return (
    <div
    className='sm:border-0 border border-1 border-HN-Grey-Border2 rounded-md p-4 sm:px-0 flex flex-col justify-center gap-4'
    >
      {/* <HeaderIcon /> */}
      <Text text="Your feedback is important to us" textClass="text-2xl font-normal" />
      <Text text={question?.QuestionDescription} textClass="text-lg font-light" />
      {/* <div className='flex flex-row mt-30 w-full'>
      {componentNames.map((componentName, i) => {
       const Component = imgComponents[componentName];
       return <div className='mx-m2'><Component key={i} circleColour={i===selectedRating ? 'black':'white'} numberColour={i===selectedRating ? 'white':'black'} handleClick={()=> actions.setSelectedRating(i)} /></div>;   
      })}
      </div> */}

      <div className="w-full flex flex-col items-center gap-2">
        <Text text={getRatingLabel(selectedRating)} textClass="text-center text-lg font-bold font-light" />

        <RangeSlider
          min={0}
          max={10}
          step={1}
          value={selectedRating}
          onChange={(e) => {
            actions.setSelectedRating(e.target.value)
          }}
        />
      </div>

      {/* <div className="flex flex-row mt-20 mb-20 justify-between w-full">
        <Text text="0=Not Likely" textClass="text-HN-Gray-Light text-left" />
        <Text text="10=Extremely Likely" textClass="text-HN-Gray-Light text-right" />
      </div> */}

      <Button
        isSelfServe={false}
        label="Next"
        disabled={selectedRating !== null ? false : true}
        className="w-full-11 lg:text-sm my-10 "
        mainbgcolor="w-full cursor-pointer px-p10 "
        borderClass="rounded-lg"
        bgColor={'bg-HN-Purple-NewDark border-HN-Grey-LightBorder '}
        disabledBgColor="bg-HN-Gray-Disabled"
        textClass={selectedRating !== null ? 'text-white text-base' : 'text-inputBorder text-base'}
        alignmentClass="text-center lg:text-left"
        onClick={() => {
          let customerCategory = getCustomerCategory(selectedRating)
          actions.setCustomerCategory(customerCategory)
          actions.setSurveyPage(SurveyPages.Questions)
        }}
      />
    </div>
  )
}

export default Rating
