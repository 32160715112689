import React, { useEffect, useRef } from 'react'
import './RangeSlider.css'

const RangeSlider = ({
  min = 0,
  max = 10,
  step = 1,
  value = min,
  onChange,
  showRangeLabel = false,
  rangeLabel = 'Sample Label',
  showStepLabel = true,
  stepLabels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  sliderClassName = '',
  labelClassName = '',
  stepLabelClassName = '',
  containerClassName = '',
}) => {
  const sliderRef = useRef(null)

  useEffect(() => {
    if (sliderRef.current) {
      const percentage = ((value - min) / (max - min)) * 100
      sliderRef.current.style.setProperty('--value', `${percentage}%`)
    }
  }, [value, min, max])

  return (
    <div className={`w-full ${containerClassName}`}>
      {showRangeLabel && (
        <label htmlFor="steps-range" className={`block mb-2 text-sm font-medium text-gray-900 dark:text-white ${labelClassName}`}>
          {rangeLabel}
        </label>
      )}

      {showStepLabel && (
        <div className="flex justify-between items-center my-2">
          {stepLabels.length > 0
            ? stepLabels.map((label, i) => (
                <span key={i} className={`text-sm font-bold ${stepLabelClassName} ${value == label ? 'font-bold text-lg' : ''}`}>
                  {label}
                </span>
              ))
            : Array.from({ length: Math.floor((max - min) / step) + 1 }, (_, i) => (
                <span key={i} className={`text-sm font-bold ${stepLabelClassName} ${value == min + i * step ? 'font-bold text-lg' : ''}`}>
                  {min + i * step}
                </span>
              ))}
        </div>
      )}
      <div className="range-slider mb-2">
        <input
          ref={sliderRef}
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
          className={`slider w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer ${sliderClassName}`}
        />
      </div>
    </div>
  )
}

export default RangeSlider
