import React from 'react'

const Number7 = ({ circleColour, numberColour, handleClick }) => (
<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
<rect x="1" y="1" width="24" height="25" rx="12" fill={circleColour}/>
<rect x="1" y="1" width="24" height="25" rx="12" stroke="#0C0E11"/>
<path d="M11.7285 17.5C11.7285 16.7633 11.8108 16.0353 11.9755 15.316C12.1402 14.588 12.3525 13.8903 12.6125 13.223C12.8725 12.547 13.1585 11.91 13.4705 11.312C13.7825 10.7053 14.0815 10.1463 14.3675 9.635H10.0645V8.569H15.7585L15.8235 9.323C15.4682 9.93833 15.1128 10.5667 14.7575 11.208C14.4108 11.8493 14.0988 12.508 13.8215 13.184C13.5528 13.86 13.3318 14.5577 13.1585 15.277C12.9938 15.9963 12.9115 16.7373 12.9115 17.5H11.7285Z" fill={numberColour}/>
</svg>
)

export default Number7