import React from 'react'

const Number10 = ({ circleColour, numberColour, handleClick }) => (
  <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
    <rect x="1" y="1" width="24" height="25" rx="12" fill={circleColour} />
    <rect x="1" y="1" width="24" height="25" rx="12" stroke="#0C0E11" />
    <path d="M15.1215 17.5H13.9905V15.433H9.83049L9.76549 14.809L13.9905 8.569H15.1215V14.419H16.1745V15.433H15.1215V17.5ZM13.9905 14.419V10.311L11.2215 14.419H13.9905Z" fill={numberColour} />
  </svg>
)

export default Number10
