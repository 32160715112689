import React from 'react'

const Number6 = ({ circleColour, numberColour, handleClick }) => (

<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
<rect x="1" y="1" width="24" height="25" rx="12" fill={circleColour}/>
<rect x="1" y="1" width="24" height="25" rx="12" stroke="#0C0E11"/>
<path d="M15.0825 10.09C14.8225 9.908 14.5365 9.76933 14.2245 9.674C13.9125 9.57 13.6222 9.518 13.3535 9.518C12.9808 9.518 12.6645 9.609 12.4045 9.791C12.1445 9.973 11.9322 10.2157 11.7675 10.519C11.6028 10.8223 11.4772 11.1733 11.3905 11.572C11.3125 11.9707 11.2648 12.3823 11.2475 12.807C11.5422 12.5123 11.8758 12.2827 12.2485 12.118C12.6212 11.9447 13.0198 11.858 13.4445 11.858C13.8345 11.858 14.1898 11.9317 14.5105 12.079C14.8398 12.2177 15.1215 12.417 15.3555 12.677C15.5895 12.9283 15.7715 13.2317 15.9015 13.587C16.0315 13.9337 16.0965 14.3107 16.0965 14.718C16.0965 15.1513 16.0228 15.55 15.8755 15.914C15.7282 16.2693 15.5245 16.577 15.2645 16.837C15.0045 17.0883 14.6968 17.2833 14.3415 17.422C13.9862 17.5607 13.6005 17.63 13.1845 17.63C12.6212 17.63 12.1402 17.5087 11.7415 17.266C11.3515 17.0147 11.0308 16.6853 10.7795 16.278C10.5282 15.8707 10.3418 15.4027 10.2205 14.874C10.1078 14.3367 10.0515 13.782 10.0515 13.21C10.0515 12.924 10.0688 12.6077 10.1035 12.261C10.1382 11.9143 10.1988 11.5633 10.2855 11.208C10.3722 10.8527 10.4892 10.5103 10.6365 10.181C10.7925 9.85167 10.9918 9.557 11.2345 9.297C11.4772 9.037 11.7675 8.829 12.1055 8.673C12.4435 8.517 12.8422 8.439 13.3015 8.439C13.6568 8.439 14.0208 8.491 14.3935 8.595C14.7662 8.699 15.1432 8.87667 15.5245 9.128L15.0825 10.09ZM12.1185 13.184C11.7978 13.3487 11.5162 13.5653 11.2735 13.834C11.3428 14.692 11.5465 15.3593 11.8845 15.836C12.2225 16.3127 12.6775 16.551 13.2495 16.551C13.4922 16.551 13.7132 16.5077 13.9125 16.421C14.1205 16.3257 14.2938 16.2 14.4325 16.044C14.5712 15.8793 14.6795 15.6843 14.7575 15.459C14.8355 15.2337 14.8745 14.9867 14.8745 14.718C14.8745 14.1807 14.7272 13.7517 14.4325 13.431C14.1378 13.1017 13.7262 12.937 13.1975 12.937C12.8075 12.937 12.4478 13.0193 12.1185 13.184Z" fill={numberColour}/>
</svg>
)

export default Number6