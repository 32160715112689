import React from 'react'

const Number5 = ({ circleColour, numberColour, handleClick }) => (

<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
<rect x="1" y="1" width="24" height="25" rx="12" fill={circleColour}/>
<rect x="1" y="1" width="24" height="25" rx="12" stroke="#0C0E11"/>
<path d="M10.5845 15.628C10.8965 15.914 11.2258 16.1393 11.5725 16.304C11.9278 16.4687 12.3265 16.551 12.7685 16.551C13.3318 16.551 13.7912 16.408 14.1465 16.122C14.5105 15.8273 14.6925 15.3853 14.6925 14.796C14.6925 14.484 14.6362 14.211 14.5235 13.977C14.4195 13.743 14.2765 13.5523 14.0945 13.405C13.9125 13.2577 13.7045 13.1493 13.4705 13.08C13.2365 13.002 12.9895 12.963 12.7295 12.963C12.5042 12.963 12.2355 12.9977 11.9235 13.067C11.6202 13.1277 11.3082 13.249 10.9875 13.431L10.4805 13.054L10.6495 8.569H15.3165V9.635H11.7675L11.6635 12.209C11.8628 12.1223 12.0838 12.0573 12.3265 12.014C12.5692 11.962 12.8075 11.936 13.0415 11.936C13.4142 11.936 13.7738 12.001 14.1205 12.131C14.4672 12.261 14.7748 12.4473 15.0435 12.69C15.3122 12.9327 15.5245 13.2317 15.6805 13.587C15.8365 13.9337 15.9145 14.328 15.9145 14.77C15.9145 15.1947 15.8408 15.5847 15.6935 15.94C15.5462 16.2867 15.3382 16.5857 15.0695 16.837C14.8008 17.0883 14.4715 17.2833 14.0815 17.422C13.7002 17.5607 13.2755 17.63 12.8075 17.63C12.2962 17.63 11.8108 17.5433 11.3515 17.37C10.8922 17.188 10.4458 16.8933 10.0125 16.486L10.5845 15.628Z" fill={numberColour}/>
</svg>
)

export default Number5