import React from 'react'
import { isEmpty } from '../../helper/helper'
import Image from '../shared/Image'
import PageHeader from '../shared/PageHeader'
import Text from '../shared/Text'

const DataMapper = ({ dataArray, pageHeader, pageText }) => (
  <div className="sm:flex sm:flex-col w-full mx-auto max-w-xl pb-4">
    <PageHeader label={pageHeader} />
    <Text text={pageText} textClass="py-4 text-base font-SamsungOne-400 text-center" />
   {!isEmpty(dataArray) && <div className="p-4">
      {dataArray?.map((data) => (
        <div className="flex justify-between p-2 border-t-2 last:border-b-2 last-of-type:border-b-2 gap-4" key={data?.Key}>
          <div className="flex-grow flex-shrink-0">{data?.Key}</div>
          <div className="flex-shrink flex-grow-0 text-right">
            {data.Key === 'Device Images' && !isEmpty(data?.Value) && data?.Value?.map((image) => <Image key={image?.Url} marginClass="ml-auto" src={image?.Url} imgClass="w-full pl-8 pt-2" />)}
            {data.Key === 'Device Images' && isEmpty(data?.Value) && '-'}
            {data.Key !== 'Device Images' && data?.Value}
          </div>
        </div>
      ))}
      <div className="border-b-2"></div>
    </div>
    }
  </div>
)

export default DataMapper
