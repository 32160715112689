import React, { useContext, useEffect, useState } from 'react'
import { useActions } from '../../Action'
import { ServicePortalPages } from '../../ActionTypes'
import { useHistory } from 'react-router-dom'
import { store } from '../../Store'
import Button from '../shared/Button'
import Input from '../shared/input'
import PageHeader from '../shared/PageHeader'
import Text from '../shared/Text'
import CheckmarkIcon from '../../assets/selfserve-assets/icon-checkmark.svg'
import IconLogo from '../../assets/selfserve-assets/TradeNSave.svg'

const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
const AlphaNumericRegex = /^[a-z0-9]+$/i

const GetEmailOtp = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { sendOtpResponse, selfServicePortal, sendSSOtpResponse, getSSTradeDetails } = state
  const { emailid, tradeid } = selfServicePortal
  const [emailErrorMsg, setEmailErrorMsg] = useState('')
  const [tradeErrorMsg, setTradeErrorMsg] = useState('')
  const history = useHistory()

  const setEmail = (val) => {
    actions.setSSPemailId(val.trim())
  }

  const setTradeId = (val) => {
    if (val === '' || (val.match(AlphaNumericRegex) && val.length <= 8)) {
      actions.setSSPTradeId(val.toUpperCase())
    }
  }

  const sendOtpRequest = () => {
    const payload = {
      email: emailid,
      tradeId: tradeid,
    }
    actions.sendSSOTPMail(payload)
  }

  useEffect(() => {
    if (sendSSOtpResponse?.message === 'OTP sent.') {
      actions.setSspPage(ServicePortalPages.VerifyOtp);
    }
  }, [sendSSOtpResponse]);

  useEffect(() => {
    if (emailid !== '' && !emailid.match(EmailRegex)) {
      setEmailErrorMsg('Invalid Email')
    } else {
      setEmailErrorMsg('')
    }
  }, [emailid])

  useEffect(() => {
    if (tradeid !== '' && tradeid[0] !== 'H') {
      setTradeErrorMsg('Invalid TradeID')
    } else {
      setTradeErrorMsg('')
    }
  }, [tradeid])

  return (
    <div className="m-15 flex flex-col justify-center loginBackgroundImg">
      <div className="px-p20 pt-10 mx-auto">
        <IconLogo className="" />
      </div>
      <PageHeader label='Trade-in Support' className="mt-2 md:mt-8"/>
      <Text
        text={`Enter your details below to check on your trade-in. Alternatively please contact us below by using the chat.`}
        textClass="text-left mt-12 md:mt-8 md:mb-0 mb-5 text-[16px]"
      />

      {sendSSOtpResponse?.message === "" && 
        <Text
          text={`The Trade ID you have entered does not exist, please check your original email and try again`}
          textClass="text-left mb-5 text-[16px] text-red-600"
        />
      }

      <Text text="Your Details" textClass="text-2xl text-center mt-10 mb-10" />
      <div className="md:w-full right-content form-row w-96 mb-5 mx-auto">
        <Input type="text" name="tradeid" label="Trade ID" placeholder="" value={tradeid} onChange={setTradeId} className="border-b" />
        <Text text={tradeErrorMsg} textClass="font-SamsungOne-400 text-red-600 text-sm" />
      </div>
      <div className="md:w-full right-content form-row w-96 mb-5 mx-auto">
        <Input type="text" name="emailid" label="Email address" placeholder="" value={emailid} onChange={setEmail} className="border-b" />
        <Text text={emailErrorMsg} textClass="font-SamsungOne-400 text-red-600 text-sm" />
      </div>
      <div className='rounded-lg mx-auto w-300'>
        <Button
          label="Continue"
          disabled={!emailid || !tradeid || emailErrorMsg.length !== 0 || tradeErrorMsg.length !== 0}
          marginClass="text-white mt-10 border rounded-md"
          borderClass="rounded-md"
          bgColor="bg-HN-Purple-Dark"
          disabledBgColor="bg-gray-400"
          onClick={sendOtpRequest}
        />
      </div>
      {/* <div className='flex text-center items-center justify-center pt-4 pb-8'>
        <span className="text-HN-Purple-Dark underline cursor-pointer" onClick={() => history.replace('/')}>
          Go back
        </span>{' '}
      </div> */}
    </div>
  )
}

export default GetEmailOtp
