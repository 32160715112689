import React from 'react';
import Text from '../shared/Text';
import TextArea from '../shared/TextArea';
import Button from '../shared/Button';
import { sanitize } from '../../helper/helper';

const FeedbackSection = ({ feedBackQuestion, comment, setComment, checkedReasons, SubmitSurvey }) => {
  const setMessage = (message) => {
    setComment(sanitize(message));
  };

  return (
    <div className="flex flex-col gap-4">
      <Text text={feedBackQuestion.QuestionDescription} textClass="text-2xl font-normal" />
      <Text text={'You can add a note below to explain in more detail'} textClass="text-lg" />
      <TextArea
        rows="5"
        placeholder={'Add your additional feedback here'}
        className="outline-none border rounded-md border-HN-Gray-newGray p-3"
        cols="50"
        minLength="50"
        maxLength="200"
        value={comment}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className="text-right text-sm text-gray-500">
        {comment.length} / 200
      </div>
      <Button
        isSelfServe={false}
        label="Submit"
        disabled={checkedReasons.length === 0}
        className="w-full lg:text-sm mt-10"
        mainbgcolor="w-full cursor-pointer px-p10"
        borderClass="rounded-lg"
        bgColor={'bg-HN-Purple-NewDark border-HN-Grey-LightBorder'}
        disabledBgColor="bg-HN-Gray-Disabled"
        textClass={checkedReasons.length > 0 ? 'text-white text-base' : 'text-inputBorder text-base'}
        alignmentClass="text-center lg:text-left"
        onClick={SubmitSurvey}
      />
    </div>
  );
};

export default FeedbackSection;