import React, { useContext, useEffect, useState } from 'react'
import { store } from '../../Store'
import { useActions } from '../../Action'
import { ServicePortalPages } from '../../ActionTypes'
import CloseIcon from '../../assets/selfserve-assets/icon-close-ss.png'
import TrustIcon from '../../assets/selfserve-assets/ic-trust-icon'
import PayPalIcon from '../../assets/selfserve-assets/ic-paypal-icon.png'
import Button from '../shared/Button'
import FloatingLabelInput from '../shared/FloatingLabelInput'
import Popup from '../shared/PopUp'
import RefundSuccess from './RefundSucess'

const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
const BsbRegex = /^[0-9]{6}$/i
const AccountNumberRegex = /^[0-9]{5,9}$/i

const RefundTrade = () => {
  const { state, dispatch } = useContext(store)
  const [payoutMethod, setPayoutMethod] = useState('Bank Account')
  const [bsb, setBsb] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [email, setEmail] = useState('')
  const [accountErrorMsg, setAccountErrorMsg] = useState('')
  const [bsbErrorMsg, setBsbErrorMsg] = useState('')
  const [emailErrorMsg, setEmailErrorMsg] = useState('')
  const [refundSuccess, setRefundSuccess] = useState(false)
  const { tradeDetails } = state
  const actions = useActions(state, dispatch)
  const { selfServicePortal, isLoading, sendSSOtpResponse,getSSTradeDetails, isPayoutSuccess, isPayoutDetailsSaved, savepayoutDetailsError } = state
  const { TradeAccess: token ,TradeId } = sendSSOtpResponse

  //scroll to top on page load
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const createUser = () => {
    const createHyperwalletUesrRequest = {
      createHyperwalletUesrRequest: {
        tradeId: getSSTradeDetails?.TradeQuoteNumber,
      },
    }
    actions.createHyperWalletUser(createHyperwalletUesrRequest,token)
  }

  useEffect(() => {
    createUser()
  }, [])

  useEffect(() => {
    if (email !== '' && !email?.match(EmailRegex)) {
      setEmailErrorMsg('true')
    } else {
      setEmailErrorMsg('')
    }
  }, [email])

  useEffect(() => {
    if (bsb !== '' && !bsb?.match(BsbRegex)) {
      setBsbErrorMsg('true')
    } else {
      setBsbErrorMsg('')
    }
  }, [bsb])

  useEffect(() => {
    if (accountNumber !== '' && !accountNumber?.match(AccountNumberRegex)) {
      setAccountErrorMsg('true')
    } else {
      setAccountErrorMsg('')
    }
  }, [accountNumber])

  const handlePayOut = () => {

   actions.clearPayOutDetails()

    const bankAccountRequest = {
      createHyperwalletTransferMethodRequest: {
        TransferAccountType: 'BANK_ACCOUNT',
        BankId: bsb?.substring(0,3),
        BranchId: bsb?.substring(3),
        BankAccountId: accountNumber,
        tradeId: getSSTradeDetails?.TradeQuoteNumber,
      },
    }

    const payPalRequest = {
      createHyperwalletTransferMethodRequest: {
        TransferAccountType: 'PAYPAL',
        EmailId: email,
        tradeId: getSSTradeDetails?.TradeQuoteNumber,
      },
    }

    actions.savePayOutDetails(payoutMethod === 'PayPal' ? payPalRequest : bankAccountRequest , token)

  }

  return (
    <div className="mx-4 justify-end flex flex-col mt-4">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <span className="text-xl font-SamsungOne-700">Update Refund Details</span>
          <img
            className="cursor-pointer sm:h-6"
            onClick={() => {
              actions.setSspPage(ServicePortalPages.TradeDetails)
              actions.clearPayOutDetails()
            }}
            src={CloseIcon}
            alt="close"
          />
        </div>
        <div className=" flex flex-col justify-center">
          <span className="text-center text-lg mt-2">Provide your payment details</span>
          <span className="text-center text-base mt-2">Please select a payment method for the refund of the Security Charge</span>
          <span className="text-center font-bold  mt-2">Payment details</span>

          <div className="flex flex-col items-center mx-auto mt-4  ">
            <div class="form">
              <div for="paymenttype" class="text-sm ml-2">
                Payout Method
              </div>
              <select
                 onChange={(e) => {
                  setPayoutMethod('')
                  setPayoutMethod(e?.target?.value)
                  setBsb('')
                  setEmail('')
                  setAccountNumber('')
                  actions.clearPayOutDetails()
                }}
                placeholder="Payout Method"
                class="block p-0 cursor-pointer rounded-lg w-221  border border-gray-200 text-gray-700 py-4 px-4 leading-tight bg-white "
                id="paymenttype"
              >
                <option selected>Bank Account</option>
                <option>PayPal</option>
              </select>
            </div>

            {payoutMethod === 'Bank Account' && (
              <FloatingLabelInput
                value={bsb}
                onChange={setBsb}
                type="text"
                id="bsb"
                name="bsb"
                placeholder="BSB"
                minLength={6}
                maxLength={6}
                className = 'mt-2'
                error={bsbErrorMsg}
                errorMsg={'Bsb should be 6 digits long.'}
              />
            )}
            {payoutMethod === 'Bank Account' && (
              <FloatingLabelInput
                value={accountNumber}
                onChange={setAccountNumber}
                type="text"
                id="accountnumber"
                name="accountnumber"
                placeholder="Account Number"
                minLength={5}
                maxLength={9}
                className='mt-2'
                error={accountErrorMsg}
                errorMsg={'Account Number should be atleat 5-9 digits long.'}
              />
            )}
            
            {payoutMethod === 'PayPal' && (
              <FloatingLabelInput 
                value={email} 
                onChange={setEmail} 
                type="text" 
                id="email" 
                name="email" 
                placeholder="Email Address" 
                error={emailErrorMsg} 
                className='mt-2'
                errorMsg={'Invalid Email Address.'} 
              />
            )}
          </div>
        </div>
         <div className = 'flex flex-col items-center mt-6'>  
        {payoutMethod === 'Bank Account' && <TrustIcon className={`h-10`} />}
        {payoutMethod === 'PayPal' && <img className={`h-16`} src={PayPalIcon} alt="logo" />}

        <span className="text-center font-bold  mt-6">*Your transaction will be processed within 5 business days.</span>
       { savepayoutDetailsError && <span className="text-center text-sm font-bold mt-10 font-SamsungOne-500 text-red-600">Oops! Something Went Wrong. Please try again.</span> }
        <Button
         type="button"
         className="my-4 "
         disabled={
            (payoutMethod === 'Bank Account' && (!bsb || !accountNumber || bsbErrorMsg?.length !== 0 || accountErrorMsg?.length !== 0)) ||
            (payoutMethod === 'PayPal' && (!email || emailErrorMsg?.length !== 0))
          }
          label="Submit"
          onClick={() => {
            handlePayOut()
          }}
          disabledBgColor="bg-HN-Grey-Light"
          textClass="px-12 text-white bg-black"
        />
        </div>
        {isPayoutSuccess && !isLoading && (
          <Popup show global title={'Success'} customeStyle="px-4 py-2" marginClass="mt-[0.5rem] ps:mt-4" className="flex justify-center items-center rounded max-w-lg border-2 border-black">
            <RefundSuccess
              handleOk={() => {actions.setSspPage(ServicePortalPages.TradeDetails); actions.getSSTradesDetails({
                  payload: {
                    tradeId: getSSTradeDetails?.TradeInfoId,
                  },
                  fromOtp: false
                })
              }}
            />
          </Popup>
        )}
      </div>
    </div>
  )
}
export default RefundTrade
