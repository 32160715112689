import React, { useContext, useEffect, useState } from 'react'
import { useActions } from '../../Action'
import { store } from '../../Store'
import Button from '../shared/Button'
import Text from '../shared/Text'
import ThankYouIcon from '../../assets/survey-assets/thankyou-icon.svg'

const ThankYou = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)

  const redirectToLogin = () => {
    const url = window.location.origin
    window.location = url
  }
  return (
    <div 
    className='sm:border-0 border border-1 border-HN-Grey-Border2 rounded-md p-4 sm:px-0 flex flex-col justify-center gap-4'
    >
      <Text text={'Thanks for your feedback!'} textClass="text-2xl font-normal" />
      <Text text={'Your response has been submitted successfully.'} textClass="text-lg" />
      <div className="flex justify-center">
        <ThankYouIcon />
      </div>
      {/* <Button
        isSelfServe={false}
        label="Close"
        disabled={false}
        className="w-full lg:text-sm mb-20  mt-20"
        mainbgcolor="w-full  cursor-pointer px-p10"
        borderClass="rounded-lg"
        bgColor={'bg-HN-Purple-NewDark border-HN-Grey-LightBorder'}
        disabledBgColor="bg-HN-Gray-Disabled"
        textClass="text-white text-base"
        alignmentClass="text-center lg:text-left"
        onClick={() => redirectToLogin()}
      /> */}
    </div>
  )
}

export default ThankYou
