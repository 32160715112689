import React, { useContext, useState, useCallback } from 'react';
import { useActions } from '../../Action';
import { store } from '../../Store';
import Button from '../shared/Button';
import Text from '../shared/Text';
import { SurveyPages } from '../../ActionTypes';
import FeedbackSection from './FeedbackSection';
import './Questions.css';

const Questions = () => {
  const { state, dispatch } = useContext(store);
  const actions = useActions(state, dispatch);
  const { surveyPages } = state;
  const { customerCategory, surveyQuestions, selectedRating, tradeid } = surveyPages;
  const [checkedReasons, setCheckedReasons] = useState([]);
  const [comment, setComment] = useState('');
  const [isReasonSubmitted, setIsReasonSubmitted] = useState(false);

  const ratingQuestion = surveyQuestions?.find((item) => item.DisplayOrder === '1');
  const questions = surveyQuestions?.find((item) => item.DisplayOrder === '2' && item?.QuestionType?.toUpperCase() === customerCategory);
  const questionsArray = questions?.AnswerOptions?.split(':');
  const feedBackQuestion = surveyQuestions?.find((item) => item.DisplayOrder === '3');

  const handleCheckboxChange = useCallback((reason) => {
    setCheckedReasons((prev) => (prev.includes(reason) ? prev.filter((item) => item !== reason) : [...prev, reason]));
  }, []);

  const getQuestionHeading = useCallback((score) => {
    if (score > 8) return 'We are glad that we met your expectation.';
    if (score > 6) return 'Please share with us where you think we can improve.';
    if (score !== null) return 'We are sorry that we didn’t meet your expectation.';
    return '';
  }, []);

  const SubmitSurvey = () => {
    const payload = {
      SubmitSurveyAnswers: {
        SurveyId: ratingQuestion?.SurveyId,
        ClientId: 'A10CCA8AB46B11EB8AC60AA01D6C824E',
        ServiceRequestId: tradeid,
        Results: [
          {
            QuestionId: ratingQuestion?.QuestionId,
            Answer: selectedRating?.toString(),
          },
          {
            QuestionId: questions?.QuestionId,
            Answer: checkedReasons.join(', '),
          },
          {
            QuestionId: feedBackQuestion?.QuestionId,
            Answer: comment,
          },
        ],
      },
    };
    actions.submitSurvey(payload);
    actions.setSurveyPage(SurveyPages.Thankyou);
  };

  return (
    <div className="sm:border-0 border border-1 border-HN-Grey-Border2 rounded-md p-4 sm:px-0 flex flex-col justify-center gap-4">
      {!isReasonSubmitted ? (
        <>
          {getQuestionHeading(selectedRating) && <Text text={getQuestionHeading(selectedRating)} textClass="text-2xl font-normal" />}
          {!(selectedRating > 6 && selectedRating < 9) && <Text text={questions.QuestionDescription} textClass="text-lg" />}
          {selectedRating > 6 && selectedRating < 9 && <Text text={'You can select more than 1.'} textClass="text-lg" />}
          <div className="flex flex-col gap-4">
            {questionsArray.map((reason, i) => (
              <div className="flex flex-row" key={i}>
                <input
                  id={`reason${i}`}
                  type="checkbox"
                  value={reason}
                  className="custom-checkbox flex-shrink-0"
                  onChange={() => handleCheckboxChange(reason)}
                />
                <label htmlFor={`reason${i}`} className="ml-2 text-md mt-0">
                  {reason}
                </label>
              </div>
            ))}
            <Button
              isSelfServe={false}
              label="Next"
              disabled={checkedReasons.length === 0}
              className="w-full lg:text-sm mb-10 "
              mainbgcolor="w-full cursor-pointer px-p10"
              borderClass="rounded-lg"
              bgColor={'bg-HN-Purple-NewDark border-HN-Grey-LightBorder'}
              disabledBgColor="bg-HN-Gray-Disabled"
              textClass={checkedReasons.length > 0 ? 'text-white text-base' : 'text-inputBorder text-base'}
              alignmentClass="text-center lg:text-left"
              onClick={() => setIsReasonSubmitted(true)}
            />
          </div>
        </>
      ) : (
        <FeedbackSection
          feedBackQuestion={feedBackQuestion}
          comment={comment}
          setComment={setComment}
          checkedReasons={checkedReasons}
          SubmitSurvey={SubmitSurvey}
        />
      )}
    </div>
  );
};

export default Questions;