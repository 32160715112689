import React from 'react'

const Number1 = ({ circleColour, numberColour, handleClick }) => (
  <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
    <rect x="1" y="1" width="24" height="25" rx="12" fill={circleColour} />
    <rect x="1" y="1" width="24" height="25" rx="12" stroke="#0C0E11" />
    <path
      d="M13.9255 16.265C14.1768 16.0743 14.3762 15.8187 14.5235 15.498C14.6795 15.1687 14.7878 14.7917 14.8485 14.367C14.9092 13.9423 14.9395 13.5003 14.9395 13.041C14.9395 12.573 14.9092 12.1267 14.8485 11.702C14.7878 11.2773 14.6795 10.9047 14.5235 10.584C14.3762 10.2547 14.1768 9.99467 13.9255 9.804C13.6828 9.61333 13.3752 9.518 13.0025 9.518C12.6298 9.518 12.3178 9.61333 12.0665 9.804C11.8238 9.99467 11.6245 10.2547 11.4685 10.584C11.3212 10.9047 11.2172 11.2773 11.1565 11.702C11.0958 12.1267 11.0655 12.573 11.0655 13.041C11.0655 13.5003 11.0958 13.9423 11.1565 14.367C11.2172 14.7917 11.3212 15.1687 11.4685 15.498C11.6245 15.8187 11.8238 16.0743 12.0665 16.265C12.3178 16.4557 12.6298 16.551 13.0025 16.551C13.3752 16.551 13.6828 16.4557 13.9255 16.265ZM15.4465 16.239C15.1952 16.655 14.8702 16.993 14.4715 17.253C14.0728 17.5043 13.5832 17.63 13.0025 17.63C12.4218 17.63 11.9322 17.5043 11.5335 17.253C11.1348 16.993 10.8098 16.655 10.5585 16.239C10.3072 15.8143 10.1252 15.3247 10.0125 14.77C9.89982 14.2153 9.84349 13.639 9.84349 13.041C9.84349 12.443 9.89982 11.8667 10.0125 11.312C10.1252 10.7573 10.3072 10.2677 10.5585 9.843C10.8098 9.41833 11.1348 9.08033 11.5335 8.829C11.9322 8.569 12.4218 8.439 13.0025 8.439C13.5832 8.439 14.0728 8.569 14.4715 8.829C14.8702 9.08033 15.1952 9.41833 15.4465 9.843C15.6978 10.2677 15.8798 10.7573 15.9925 11.312C16.1052 11.8667 16.1615 12.443 16.1615 13.041C16.1615 13.639 16.1052 14.2153 15.9925 14.77C15.8798 15.3247 15.6978 15.8143 15.4465 16.239Z"
      fill={numberColour}
    />
  </svg>
)

export default Number1
