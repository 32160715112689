import React, { useState, useContext, useEffect } from 'react'
import { useActions } from '../Action'
import { store } from '../Store'
import ProgressBar from './ProgressBar'
import SelectCategory from './DeviceSelection/SelectCategory'
import { MemoryRouter as Router, Switch, Route } from 'react-router-dom'
import { toPascalCase } from '../utils/helper'
String.prototype.toPascalCase = toPascalCase
import IconLogo from '../Icons/icon-logo'
import IconSelfServ from '../assets/icon-self-serv.svg'
import FaqIcon from '../assets/faq-icon.svg'
import CustomerInfo from './Customer/CustomerInfo'
import OtpVerification from './Customer/OtpVerification'
import DeviceInEligible from './DeviceInformation/DeviceInEligible'
import DeviceSerialNumber from './DeviceInformation/DeviceSerialNo'
import TermsAndConditions from './TermsAndConditions/TermsAndConditions'
import Summary from './Summary/Summary'
import TradeInfo from './DeviceInformation/TradeInfo'
import UploadId from './IDCapture/UploadID'
import SuccessPopUp from './IDCapture/SuccessPopup'
import ScrollToTop from './ScrollToTop'
import FAQList from './FAQ/index'
import TagManager from 'react-gtm-module'
import DevicePhysicalCondition from './DeviceInformation/DevicePhysicalCondition'
import AddCard from './Phone/AddCreditCard'
import AddCardNew from './Phone/AddCreditCardNew'
import SecurityChargeFAQ from './Phone/SecurityChargeFAQ'

import { Helmet } from 'react-helmet'
import { config } from '../config'
import SelfServicePortal from '../components/selfserviceportal/SelfServicePortal'
import PaymentConfirmation from './Phone/PaymentConfirmation'
import Survey from './Survey/Survey'

const GTMid = process.env.GTMid
const tagManagerArgs = {
  gtmId: GTMid,
}

const App = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { resources, isLoading } = state

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
    actions.getMasterData()
  }, [])

  const [isssp, setIsssp] = useState(true);

  useEffect(() => {
    window.addEventListener('popstate', function (event) {
      location.reload();
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta name="robots" content={config.APP.isProd ? 'index,follow' : 'noindex, nofollow'} />
        {!config.APP.isProd && <meta name="googlebot" content="none" />}
      </Helmet>
      {isLoading && <ProgressBar />}
      {resources && (
        <Router>
          <ScrollToTop />
          <div
            id="basediv"
            className="overflow-y-auto h-screen"
          // style={{ background: window.location.pathname !== '/manage' ? 'linear-gradient(90deg, #FDFDFE 0.62%, #E8E8E9 100vh)' : ''}} removed for HN 2.1
          >
            {(!['/manage', '/survey'].includes(window.location.pathname)  && (<div>
                <div className="flex flex-row justify-between md:px-p20 px-20 pt-p20">
                  <div onClick={() => window.location.reload()}>
                  <IconLogo />
                  </div>
                  <div className='flex flex-row'>
                    <div className='md:mr-2.5 mr-5' onClick={() => { window.open('/manage'), setIsssp(false) }}>
                     <IconSelfServ />
                    </div>
                    <div onClick={() => window.open('/faq')}>
                      <FaqIcon />
                    </div>
                  </div>
                </div>
                {/* <div className='flex flex-row-reverse'>
                  <
                </div> */}
              </div>
              )
            )}
            <Switch>
              
              {/* <Route path="/selectdevice" exact default>
                <SelectDevice actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/selectsurfacedevice" exact default>
                <SelectSurfaceDevice actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/selectlvdcategory" exact default>
                <SelectLvdCategory actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/selectlvddevice" exact default>
                <SelectLvdDevice actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/sysinfo" exact default>
                <SystemInformation actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/selectsubcategory" exact default>
                <SelectSubCategory actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/selectlaptopcategory" exact default>
                <SelectLaptopCategory actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/myselecteddevice" exact default>
                <MySelectedDevice actions={actions} state={state} resources={resources} />
              </Route> */}
              <Route path="/customerinfo" exact default>
                <CustomerInfo actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/otpverification" exact default>
                <OtpVerification actions={actions} state={state} resources={resources} />
              </Route>
              {/* <Route path="/deviceonoff" exact default>
                <DeviceOnOff actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/phycon" exact default>
                <PhysicalCondition actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/surfacephycon" exact default>
                <SurfacePhysicalCondition actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/lvdphycon" exact default>
                <LvdPhysicalCondition actions={actions} state={state} resources={resources} />
              </Route> */}
              <Route path="/ineligible" exact default>
                <DeviceInEligible actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/terms" exact default>
                <TermsAndConditions actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/serialno" exact default>
                <DeviceSerialNumber actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/summary" exact default>
                <Summary actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/tradeinfo" exact default>
                <TradeInfo actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/addcard" exact default>
                <AddCard actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/addcardnew" exact default>
                <AddCardNew actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/securitychargefaq" exact default>
                <SecurityChargeFAQ actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/confirmpayment" exact default>
                <PaymentConfirmation actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/uploadid" exact default>
                <UploadId actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/sucesspopup" exact default>
                <SuccessPopUp actions={actions} state={state} resources={resources} />
              </Route>
              {/* <Route path="/manufacturer" exact default>
                <DeviceManufacturer actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/phonemanufacturer" exact default>
                <PhoneManufacturer actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/selectphonemodel" exact default>
                <PhoneModel actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/selectStorage" exact default>
                <PhoneStorage actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/myselectedphone" exact default>
                <MySelectedPhone actions={actions} state={state} resources={resources} />
              </Route> */}
              <Route path="/devicephysicalcondition" exact default>
                <DevicePhysicalCondition actions={actions} state={state} resources={resources} />
              </Route>
              {/* <Route path="/selectseries" exact default>
                <Series actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/selectmydevice" exact default>
                <SelectMyDevice actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/phyconwearable" exact default>
                <PhyConWearables actions={actions} state={state} resources={resources} />
              </Route> */}
              <Route path="/faq" exact>
                <FAQList actions={actions} state={state} resources={resources} />
              </Route>
              {/* <Route path="/sysinfowearables" exact>
                <SystemInformationWearables actions={actions} state={state} resources={resources} />
              </Route> */}
              <Route path="/manage" exact>
                <SelfServicePortal actions={actions} state={state} resources={resources} />
              </Route>
              <Route path="/survey" exact>
                  <Survey />
              </Route>
              <Route path="/">
                <SelectCategory actions={actions} state={state} resources={resources} setIsssp={setIsssp} />
              </Route>
            </Switch>
          </div>
        </Router>
      )}
    </>
  )
}

export default App
