import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'

const SelectCategory = (props) => {
  const { state, actions, setIsssp } = props
  const { selectedCategory, masterData } = state
  const history = useHistory()
  const [dataSet, setDataSet] = useState([])
  const [typeOfDevices, setTypeOfDevices] = useState([])
  const [typeOfBrands, setTypeOfBrands] = useState([])
  const [typeOfModels, setTypeOfModels] = useState([])
  const [typeOfVariant, setTypeOfVariant] = useState([])
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedModel, setSelectedModel] = useState(null)
  const [selectedVariant, setSelectedVariant] = useState(null)
  const [val, setVal] = useState()

  const reset = () => { 
    setVal([])
  }
  const messageRef = useRef();
  let scrollDown = () => {
    setTimeout(() => {
      messageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 10);
  }

  let isMobile = window.innerWidth < 768
  const filteredDevices = masterData && [...new Set(masterData.map((obj) => obj.AssetCatalogName))]

  const selectOptionsList = filteredDevices.map((item) => ({
    label: item,
    value: item
  }))

  useEffect(() => {
    if (masterData?.length > 0) {
      let filterOfDevices = [...new Set(masterData.map((obj) => obj.ClientEquipmentType))]
      setTypeOfDevices(filterOfDevices)
      scrollDown()
    }
  }, [masterData?.length > 0 ? true : false])

  useEffect(() => {
    if (selectedCategory) {
      let filterOfDevices = [...new Set(dataSet.map((obj) => obj.AssetMakeName))]?.sort((a, b) => {
        if (a.toLowerCase() === 'other') return 1;
        if (b.toLowerCase() === 'other') return -1;
        return a.localeCompare(b);
      });
      setTypeOfBrands(filterOfDevices)
      scrollDown()
    }
  }, [selectedCategory])

  useEffect(() => {
    if (selectedBrand) {
      let filterOfDevices = [...new Set(dataSet.map((obj) => obj.ClientAssetModelNbr))]?.sort((a, b) => {
        if (a.toLowerCase() === 'other') return 1;
        if (b.toLowerCase() === 'other') return -1;
        return a.localeCompare(b);
      });
      setTypeOfModels(filterOfDevices)
      scrollDown()
    }
  }, [selectedBrand])

  useEffect(() => {
    if (selectedModel) {
      let filterOfDevices = [...new Set(dataSet.map((obj) => obj.ClientMemoryCapacity))]?.sort((a, b) => {
        if (a.toLowerCase() === 'other') return 1;
        if (b.toLowerCase() === 'other') return -1;
        return a.localeCompare(b);
      });
      setTypeOfVariant(filterOfDevices)
      scrollDown()
    }
  }, [selectedModel])

  const handleDevice = (e) => {
    let mainDevice = masterData.filter(res => res.AssetCatalogName == e.value)
    setVal([e])
    actions.setDeviceType(e)
    actions.setSelectedDevice(mainDevice)
    console.log("mainDevice>>>",mainDevice)
    actions.setCategory(mainDevice?.[0]?.ClientEquipmentType)
    setSelectedBrand(mainDevice?.[0]?.AssetMakeName)
    setSelectedModel(mainDevice?.[0]?.ClientAssetModelNbr)
    setSelectedVariant(mainDevice?.[0]?.ClientMemoryCapacity)
    setDataSet(mainDevice)
  }


  const handleDesktopDevice = () => {
    let val = document.getElementById('device') && document.getElementById('device').value
    let mainDevice = masterData.filter(res => res.AssetCatalogName == val)
    actions.setDeviceType(val)
    setDataSet(mainDevice)
    actions.setSelectedDevice(mainDevice)
    actions.setCategory(mainDevice?.[0]?.ClientEquipmentType)
    setSelectedBrand(mainDevice?.[0]?.AssetMakeName)
    setSelectedModel(mainDevice?.[0]?.ClientAssetModelNbr)
    setSelectedVariant(mainDevice?.[0]?.ClientMemoryCapacity)
  }

  useEffect(() => {
    if (window.location.pathname === '/faq') {
      history.replace('/faq')
    }
    if (window.location.pathname === '/manage') {
      history.replace('/manage')
    }
    if (window.location.pathname === '/survey') {
      history.replace('/survey')
    }
    actions.setPhoneStorage(null)
    actions.setCategory(null)
  }, [])

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  }



  const setQuoteDevice = () => {
    actions.setDeviceType(dataSet[0].ClientEquipmentType)
    actions.setSelectedDevice([dataSet[0]])
    history.replace('/customerinfo')
  }

  return (
    <div ref={messageRef} className="md:mx-m15 xl:mx-m15 md:w-auto mx-auto xl:w-auto w-1150 mb-5">
      <div className="flex flex-row justify-center items-center mt-m30">
        <div className="md:w-full w-768 font-bold md:text-f22 md:leading-30 text-center text-40 px-2 leading-54">
          Find your device and trade-in for credit at a participating store
        </div>

      </div>
      <div className="md:w-auto mx-auto mt-3 xl:w-auto w-768">
        <div className="flex md:flex-col flex-row  justify-center items-center">
          {isMobile ? (
            <div className="md:m-1 md:w-full">
              <Select
                className={`bg-white text-base`}
                classNamePrefix="select"
                isDisabled={false}
                isSearchable={true}
                name="devicepopularname"
                placeholder='Quickly search for your device'
                options={selectOptionsList}
                onChange={handleDevice}
                styles={customStyles}
                id="device"
                value={val}
              />
            </div>
          ) : (
            <div className="w-full">
              <input
                list="list"
                name="device"
                id="device"
                placeholder='Quickly search for your device'
                className="h-50 border rounded-md p-3 text-HN-Gray-Medium w-full"
                onChange={handleDesktopDevice}
              />
              <datalist id="list">
                {selectOptionsList &&
                  selectOptionsList.map((item) => {
                    return <option value={item.value} label={item.label} />
                  })}
              </datalist>
            </div>
          )}
        </div>
        <div className="flex flex-row mt-3 mx-auto items-center justify-center">
          <hr className="md:w-120 w-full border border-HN-Purple-Dark"></hr>
          <p className="px-3">or</p>
          <hr className="md:w-120 w-full border border-HN-Purple-Dark"></hr>
        </div>
      </div>
      <div className="md:w-auto md:mt-2 2xl:mt-4 mt-12 mx-auto">
        <p className="font-bold md:text-f22 md:leading-30 text-center text-40 leading-54">
          Select a type of device
        </p>
      </div>
      <div className="flex flex-wrap justify-start md:justify-around  ">
        {typeOfDevices && typeOfDevices.length > 0 && typeOfDevices.map((item, index) => {
          return <div className="flex" id={index}>
            <div className={`flex flex-row border md:mx-2 mx-6  justify-center items-center md:w-135 md:h-47 w-135 h-135 md:mt-m20 mt-12 cursor-pointer rounded-r4 bg-white ${selectedCategory == item ? 'border-HN-Purple-Dark border-b3' : 'border-HN-Gray-Dark border-1'}`} onClick={() => {
              actions.setCategory(item);
              setDataSet(masterData.filter(res => res.ClientEquipmentType == item));
              setSelectedBrand(null);
              setSelectedModel(null);
              setSelectedVariant(null);
              document.getElementById('device').value = '';
              reset()
            }}>
              <div >
                <p className={`cursor-pointer text-center my-4 md:text-sm text-xl ${selectedCategory == item ? 'text-HN-Purple-Dark' : 'text-HN-Subtext-Dark'}`}                >
                  {item}
                </p>
              </div>
            </div>
          </div>
        })}
      </div>
      {
        selectedCategory && <div>
          <div className="flex flex-row md:my-6 mt-10 mx-auto items-center justify-center">
            <hr className="w-full border border-HN-Purple-Dark"></hr>
          </div>
          <div className="md:w-auto md:mt-2 2xl:mt-4 mt-12 mx-auto">
            <p className="font-bold md:text-f22 md:leading-30 text-center text-40 leading-54">
              Select the brand name
            </p>
          </div>
          <div className="flex flex-wrap justify-start md:justify-around ">
            {typeOfBrands?.length > 0 && typeOfBrands.map((item, index) => {
              return <div className="flex" id={index}>
                <div className={`flex flex-row border mx-6 md:mx-2 justify-center items-center md:w-135 md:h-47 w-135 h-135 md:mt-m20 mt-12 cursor-pointer rounded-r4 bg-white  ${selectedBrand == item ? 'border-HN-Purple-Dark border-b3' : 'border-HN-Gray-Dark border-1'}`} onClick={() => {
                  setSelectedBrand(item);
                  setDataSet(masterData.filter(res => res.AssetMakeName == item && res.ClientEquipmentType == selectedCategory));
                  setSelectedModel(null);
                  setSelectedVariant(null);
                  document.getElementById('device').value = '';
                  reset()
                }}>
                  <div>
                    <p className={`cursor-pointer text-center my-4 md:text-sm text-xl ${selectedBrand == item ? 'text-HN-Purple-Dark' : 'text-HN-Subtext-Dark'}`}           >
                      {item}
                    </p>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      }

      {
        selectedCategory && selectedBrand && <div>
          <div className="flex flex-row md:my-6 mt-10 mx-auto  items-center justify-center">
            <hr className="w-full border border-HN-Purple-Dark"></hr>
          </div>
          <div className="md:w-auto md:mt-2 2xl:mt-4 mt-12 mx-auto">
            <p className="font-bold md:text-f22 md:leading-30 text-center text-40 leading-54">
              Select the model name
            </p>
          </div>
          <div className="flex flex-wrap justify-start md:justify-around ">
            {typeOfModels?.length > 0 && typeOfModels.map((item, index) => {
              return <div className="flex" id={index}>
                <div className={`flex flex-row mx-6 md:mx-2 border justify-center items-center px-3 md:w-135 md:h-auto w-135 h-135 md:mt-m20 mt-12 cursor-pointer rounded-r4 bg-white  ${selectedModel == item ? 'border-HN-Purple-Dark border-b3' : 'border-HN-Gray-Dark border-1'}`} onClick={() => {
                  setSelectedModel(item);
                  setDataSet(masterData.filter(res => res.ClientAssetModelNbr == item && res.AssetMakeName == selectedBrand && res.ClientEquipmentType == selectedCategory));
                  setSelectedVariant(null);
                  document.getElementById('device').value = '';
                  reset()
                }}>
                  <div>
                    <p className={`cursor-pointer text-center my-4 md:text-sm text-xl ${selectedModel == item ? 'text-HN-Purple-Dark' : 'text-HN-Subtext-Dark'}`}>
                      {item}
                    </p>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      }
      {
        selectedCategory && selectedBrand && selectedModel && selectedModel?.toUpperCase() !== 'OTHER' && <div>
          <div className="flex flex-row md:my-6 mt-10 mx-auto  items-center justify-center">
            <hr className="w-full border border-HN-Purple-Dark"></hr>
          </div>
          <div className="md:w-auto md:mt-2 2xl:mt-4 mt-12 mx-auto">
            <p className="font-bold md:text-f22 md:leading-30 text-center text-40 leading-54">
              Select the variant
            </p>
          </div>
          <div className="flex flex-wrap justify-start md:justify-around ">
            {typeOfVariant?.length > 0 && typeOfVariant.map((item, index) => {
              return <div className="flex" id={index}>
                <div className={`flex flex-row border md:mx-2 mx-6 justify-center px-3 items-center md:w-135 md:h-auto w-135 h-135 md:mt-m20 mt-12 cursor-pointer rounded-r4 bg-white  ${selectedVariant == item ? 'border-HN-Purple-Dark border-b3' : 'border-HN-Gray-Dark border-1'}`} onClick={() => {
                  setSelectedVariant(item);
                  scrollDown();
                  document.getElementById('device').value = '';
                  reset();
                  setDataSet(masterData.filter(res => res.ClientMemoryCapacity == item && res.ClientAssetModelNbr == selectedModel && res.AssetMakeName == selectedBrand && res.ClientEquipmentType == selectedCategory))
                }}>
                  <div>
                    <p className={`cursor-pointer text-center my-4 md:text-sm text-xl ${selectedVariant == item ? 'text-HN-Purple-Dark' : 'text-HN-Subtext-Dark'}`}>
                      {item}
                    </p>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      }
      {
        selectedCategory && selectedBrand && selectedModel && (selectedVariant || selectedModel?.toUpperCase() === 'OTHER') && <div>
          <div className="flex flex-row mt-6 mx-auto items-center justify-center">
            <hr className="w-full border border-HN-Purple-Dark"></hr>
          </div>
          <div className="md:w-auto 2xl:mt-4 mt-12 mx-auto">
            <p className="font-bold md:text-32 text-center text-64 leading-54">
              {`Get up to $${dataSet[0].GwoPrice && dataSet[0].GwoPrice > 0 ? dataSet[0].GwoPrice : 1}*`}
            </p>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="pt-5 pb-2 md:mt-5 md:mt-8 mt-16 text-center md:px-8 px-20 border-b3 border-HN-Purple-Dark">
              <div className='md:text-2xl text-32 text-center font-bold'>
                {dataSet[0].AssetCatalogName}
              </div>
              <div className='md:text-sm text-xl md:mt-4 mt-10'>Proceed to lock in a trade-in value</div>
              <button
                id="btn-next"
                className='primary-btn md:w-174 w-345 bg-HN-Purple-Dark text-white h-50 md:h-44 md:mt-4 mt-10 text-f22'
                type="button"
                onClick={() => setQuoteDevice()}
              >
                Continue
              </button>
              <div className='text-xs md:mt-4 mt-10'>*depending on device condition</div>
            </div>
          </div>
        </div>
      }
    </div >
  )
}

export default SelectCategory
