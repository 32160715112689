import React, { useContext, useState, useEffect } from 'react'
import AsurionLogo from '../../assets/survey-assets/asurion-logo.svg'

const SurveyHeader = () => {
  const getIsMobile = () => window.innerWidth <= 416;
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
      <div>
        <AsurionLogo className="w-24 h-8" />
      </div>
  )
}

export default SurveyHeader
