import React, { useContext, useState, useEffect } from 'react'
import Rating from './Rating'
import Questions from './Questions'
import Thankyou from './Thankyou'
import { store } from '../../Store'
import { SurveyPages } from '../../ActionTypes'
import { useHistory } from 'react-router-dom'
import SurveyHeader from '../shared/surveyHeader'


const Survey = () => {
  const { state } = useContext(store)
  const history = useHistory()
  const getIsMobile = () => window.innerWidth <= 1024;

  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  let {
    surveyPages: { currentPage }
  } = state

  

  return (
    <div className='flex justify-center'>
      {/*  items-center h-screen */}
      <div
        style={{ backgroundColor: 'white' }}
        className={`flex flex-col justify-start md:mx-auto p-4 gap-4 max-w-2xl`} //w-full md:w-2/3
      >
        <SurveyHeader  />
        <div>
        {currentPage === SurveyPages.Rating && <Rating  />}
        {currentPage === SurveyPages.Questions && <Questions />}
        {/* {showHeader && <Header />} */}
        {currentPage === SurveyPages.Thankyou && <Thankyou />}
        </div>
      </div>
    </div>
  )
}

export default Survey
