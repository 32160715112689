import React from 'react'

const Number4 = ({ circleColour, numberColour, handleClick }) => (

<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
<rect x="1" y="1" width="24" height="25" rx="12" fill={circleColour}/>
<rect x="1" y="1" width="24" height="25" rx="12" stroke="#0C0E11"/>
<path d="M10.2855 15.615C10.4155 15.745 10.5715 15.8663 10.7535 15.979C10.9355 16.0917 11.1305 16.1913 11.3385 16.278C11.5465 16.356 11.7632 16.421 11.9885 16.473C12.2225 16.525 12.4522 16.551 12.6775 16.551C12.9288 16.551 13.1672 16.5207 13.3925 16.46C13.6265 16.3907 13.8302 16.291 14.0035 16.161C14.1768 16.031 14.3112 15.875 14.4065 15.693C14.5105 15.5023 14.5625 15.2813 14.5625 15.03C14.5625 14.77 14.5062 14.5403 14.3935 14.341C14.2808 14.1417 14.1292 13.9727 13.9385 13.834C13.7565 13.6867 13.5398 13.5783 13.2885 13.509C13.0372 13.431 12.7728 13.392 12.4955 13.392H11.7415V12.339H12.3525C12.5952 12.339 12.8248 12.3087 13.0415 12.248C13.2668 12.1873 13.4618 12.0963 13.6265 11.975C13.7912 11.8537 13.9212 11.702 14.0165 11.52C14.1118 11.338 14.1595 11.13 14.1595 10.896C14.1595 10.454 14.0078 10.116 13.7045 9.882C13.4098 9.63933 13.0545 9.518 12.6385 9.518C12.2918 9.518 11.9408 9.60033 11.5855 9.765C11.2388 9.921 10.9008 10.1507 10.5715 10.454L9.96049 9.583C10.3765 9.18433 10.8142 8.894 11.2735 8.712C11.7328 8.53 12.1965 8.439 12.6645 8.439C13.0805 8.439 13.4575 8.504 13.7955 8.634C14.1335 8.764 14.4195 8.94167 14.6535 9.167C14.8875 9.38367 15.0652 9.635 15.1865 9.921C15.3165 10.207 15.3815 10.506 15.3815 10.818C15.3815 11.286 15.2472 11.6847 14.9785 12.014C14.7185 12.3433 14.3588 12.612 13.8995 12.82C14.1508 12.9153 14.3892 13.0323 14.6145 13.171C14.8398 13.3097 15.0392 13.4743 15.2125 13.665C15.3858 13.847 15.5245 14.0593 15.6285 14.302C15.7325 14.536 15.7845 14.8003 15.7845 15.095C15.7845 15.4243 15.7152 15.745 15.5765 16.057C15.4378 16.3603 15.2342 16.629 14.9655 16.863C14.7055 17.097 14.3805 17.2833 13.9905 17.422C13.6092 17.5607 13.1715 17.63 12.6775 17.63C12.4435 17.63 12.1965 17.6083 11.9365 17.565C11.6765 17.5303 11.4165 17.4697 11.1565 17.383C10.8965 17.2877 10.6365 17.1663 10.3765 17.019C10.1252 16.8717 9.89115 16.694 9.67449 16.486L10.2855 15.615Z" fill={numberColour}/>
</svg>
)

export default Number4