import types from './ActionTypes'
import EvaluationObejcts from "./utils/constants.json"


const initialState = {
  isLoading: false,
  isError: false,
  DeviceType: null,
  CustomerInfo: {},
  SurfaceQuestionAnswersJson: [],
  CreateQuoteResponse: {},
  CreateQuoteRequest: {},
  CreateQuoteTestsArray: [],
  deviceEvalutionObject: EvaluationObejcts?.deviceEvalutionObject,
  deviceEvalutionObjectPhone: EvaluationObejcts?.deviceEvalutionObjectPhone,
  deviceEvalutionObjectSurface: EvaluationObejcts?.deviceEvalutionObjectSurface,
  deviceEvalutionObjectLvd: EvaluationObejcts?.deviceEvalutionObjectLvd,
  deviceEvalutionObjectWearables: EvaluationObejcts?.deviceEvalutionObjectWearables,
  PhoneQuestionAnswersJson: [
    {
      questioncode: 'HN2.1_Q1',
      title: 'PhyDamage',
      question: '<b>Is it free from physical damage?</b><br>Such as cracks, dents and bends.',
      answer: '',
      GWO: 'Yes',
      NGWO: 'No',
      WO: '',
    },
    {
      questioncode: 'HN2.1_Q2',
      title: 'Display',
      question: '<b>Is it free from any display quality issues?</b><br>Such as burn in, discolouration or bleeding.',
      answer: '',
      GWO: 'Yes',
      NGWO: 'No',
      WO: '',
    },
    {
      questioncode: 'HN2.1_Q3',
      title: 'Function',
      question: '<b>Does it function normally?</b><br>Bluetooth, Wi-FI, cameras, microphones, touchscreens and sensors all function.',
      answer: '',
      GWO: 'Yes',
      NGWO: 'No',
      WO: '',
    },
    {
      questioncode: 'HN2.1_Q4',
      title: 'Battery',
      question: '<b>Are there any issues with the battery?</b><br>Such as turning off when not plugged in or a warning message.',
      answer: '',
      GWO: 'No',
      NGWO: 'Yes',
      WO: '',
    },
    {
      questioncode: 'HN2.1_Q5',
      title: 'OnOff',
      question: '<b>Does it turn on and off?</b><br>As well as charge when plugged in and charge wirelessly - <i>if applicable</i>',
      answer: '',
      GWO: 'Yes',
      NGWO: 'No',
      WO: '',
      Ineligible: 'OnOff'
    },
    {
      questioncode: 'HN2.1_Q6',
      title: 'LiqDamage',
      question: '<b>Are there any signs of liquid damage?</b><br>Such as rust or corrosion.',
      answer: '',
      GWO: 'No',
      NGWO: 'Yes',
      WO: '',
      Ineligible: 'LiqDamage'
    },
    {
      questioncode: 'HN2.1_Q7',
      title: 'MissParts',
      question: '<b>Does it have parts missing or contain non-genuine parts?</b><br>For example, a stylus or a non-genuine display.',
      answer: '',
      GWO: 'No',
      NGWO: 'Yes',
      WO: '',
      Ineligible: 'MissParts'
    },

    {
      questioncode: 'HN2.1_Q8',
      title: 'AccountLock',
      question: '<b>Are all accounts (Apple, Google, Samsung etc.) removed?</b><br>Including any activation locks.',
      answer: '',
      GWO: 'Yes',
      NGWO: 'No',
      Ineligible: 'On',
      WO: ''
    },
    {
      questioncode: 'HN2.1_Q9',
      title: 'Stolen',
      question: '<b>Has it been reported lost or stolen?</b>',
      answer: '',
      GWO: 'No',
      NGWO: 'Yes',
      WO: '',
      Ineligible: 'Stolen'
    }
  ],
  FAQ: [
    {
      Question: 'What is Trade-In with Asurion?',
      Answer:
        'Trade-In with Asurion is a device trade-in program run by Harvey Norman®’s partner: Asurion. Trade in your eligible used devices for a store credit which can be used towards any purchase at participating Harvey Norman®, Domayne® or Joyce Mayne® stores across Australia. ',
      isopen: '0',
    },
    {
      Question: 'What devices are eligible for trade in with Asurion?',
      Answer: `The Trade-In program accepts a range of electronic equipment including smartphones, tablets, smart watches, fitness trackers, computers (laptops and desktops), and cameras.  <br/><br/>
      The list of accepted devices is continuing to expand! To find out if your device is eligible to be traded in, please use the eligibility checker at <a href="http://tradeinwith.asurion.com.au/" target="_blank" rel="noopener noreferrer" style = "color:#6B4E9F; text-decoration: underline;" >http://tradeinwith.asurion.com.au/</a> <br/><br/>
        <b>Note: </b> Devices eligible for trade in are determined by Asurion and are subject to change. Smart watches and laptops must be traded in with the original charger where the trade-in value exceeds $1.00 (excluding any bonus offers). `,
      isopen: '0',
    },
    // {
    //   Question: 'How does it work? ',
    //   Answer:
    //     'Trade-in with Asurion has made it easy to trade in your eligible used devices. Just answer a few questions regarding the brand, model and condition of your device. We’ll then provide you with an estimated trade‑in value, and if you accept, you can use the instant credit for any purchase at participating Harvey Norman®, Domayne® or Joyce Mayne® stores across Australia.',
    //   isopen: '0',
    // },
    {
      Question: 'How do I trade-in my device?',
      Answer: `Visit the trade-in website  at <a href='http://tradeinwith.asurion.com.au' target="_blank" rel="noopener noreferrer" style = "color:#6B4E9F; text-decoration: underline;" >http://tradeinwith.asurion.com.au</a> and answer a few questions regarding the brand, model and condition of your device. We will also ask you to confirm that you are the owner of the device and provide a valid form of photo identification. You will then be provided with an estimated trade in value, and if you accept, you can use the instant credit towards any purchase at participating Harvey Norman®, Domayne® or Joyce Mayne® stores across Australia. <br /><br />`,
      isopen: '0',
    },
    {
      Question: 'How do I use my trade-in credit?',
      Answer: `Upon completion of your eligible device trade-in online, you will be issued with a unique Trade ID. You must present the Trade ID in store within 7 days, otherwise it will expire.<br /><br />
      Once your Trade ID is presented in store, you must post in your existing device to Asurion within the next 7 days in accordance with the instructions emailed to you.`,
      isopen: '0',
    },
    {
      Question: 'How do I know what my device is worth?',
      Answer: `It all depends on the type of device, brand, model, condition and age. Answer a few questions about your device accurately, and an estimated trade-in value will be presented to you. Bonus credit offers may also be available from time to time, even if your device is only valued at $1.00! Bonus credit offers are subject to their own terms & conditions, ask in store for further details.  <br /><br />
      Remember that your device needs to match the condition you confirmed during the trade-in process online. If our final assessment determines that your device does not qualify for the Trade-In program, an In-eligible Device Fee will apply and the device you sent in will be returned to You. Click <a href= ${process.env.TANDC_LINK} target="_blank" rel="noopener noreferrer" style = "color:#6B4E9F; text-decoration: underline; margin-right: 4px" >here </a> to read the full terms & conditions.`,
      isopen: '0',
    },
    {
      Question: 'How do you keep me updated about my trade-in?',
      Answer: `No matter what, you’ll receive email updates about the progress of your trade in, so you’ll know what’s happening at every step.`,
      isopen: '0',
    },
    {
      Question: 'What can I do if my device is not eligible?',
      Answer: `Unfortunately, if the device is not eligible then we can’t offer a trade-in value for it. However, you may be eligible to recycle your device and receive additional bonus credit (subject to promotional offers available). To check if your device is eligible please use the eligibility checker at <a href='http://tradeinwith.asurion.com.au/' target="_blank" rel="noopener noreferrer" style = "color:#6B4E9F; text-decoration: underline;" >http://tradeinwith.asurion.com.au/</a>`,
      isopen: '0',
    },
    {
      Question: 'Can I cancel my trade in?',
      Answer: `Yes, only if you have not yet returned your device to Asurion. If you request to cancel the trade-in after using the trade-in credit in store, we will keep the Security Charge.`,
      isopen: '0',
    },
    {
      Question: 'Can I trade in more than one device at a time?',
      Answer: `You can trade in as many devices as you like, but each eligible device traded in will have a unique Trade ID associated with it. You can use up to three Trade IDs in one transaction towards any purchase at participating Harvey Norman®, Domayne® or Joyce Mayne® stores.`,
      isopen: '0',
    },
    {
      Question: 'Why do you need my IMEI or serial number?',
      Answer: `Asurion must collect either the IMEI or serial number of the device being traded in. This is so we can match the device and also ensure that we are not receiving stolen devices. Any device with an IMEI will be checked against the Australian Mobile Telecommunications Association’s (AMTA) register of reported lost or stolen devices.  `,
      isopen: '0',
    },

    {
      Question: 'How do I find my IMEI or serial number?',
      Answer: `
      For smartphones and most tablets, you can find the IMEI number easily by opening the phone keypad and typing *#06#. The IMEI will display on the screen. 
      <br></br>
      Some devices will have the serial number engraved on the outside (usually at the back). 
      <br></br>
      Device manufacturers will have instructions that you can look up on their website which will tell you where to find the serial number for your particular device.  <br /><br />
      If you have a Macbook or Apple Watch please use this <a href=${process.env.MAC_SERIALNO_LINK} target="_blank" rel="noopener noreferrer" style = "color:#6B4E9F; text-decoration: underline;" >guide</a>.<br /><br />
     
      If you have a Samsung Watch please use this <a href=${process.env.SAMSUNG_SERIAL_NO_LINK} target="_blank" rel="noopener noreferrer" style = "color:#6B4E9F; text-decoration: underline;" >guide</a>.<br /><br />
      `,
      isopen: '0',
    },
    {
      Question: 'What happens to my personal data?',
      Answer: `Before you send in your device to the Asurion, we recommend that yourback-up all data and ensure any personal information is completely removed from your device. You will also need to remove any SIM cards or memory cards from your device.`,
      isopen: '0',
    },
    {
      Question: 'How do I turn off Find My Device or activation locks?',
      Answer: `For Apple devices - To turn off Find My please follow this <a href =${process.env.FIND_MY_MAC_LINK} target = '_blank' rel = 'noopener noreferrer' style = 'color:#6B4E9F; text-decoration: underline;' > guide</a >.<br />
      For Samsung - To turn off Samsung activation locks please follow this <a href =${process.env.FIND_MY_SAM_LINK} target = '_blank' rel = 'noopener noreferrer' style = 'color:#6B4E9F; text-decoration: underline;' > guide</a >. <br/>
      `,
      isopen: '0',
    },
    {
      Question: 'How do I send in my device?',
      Answer: `Asurion will e-mail you instructions and an Australia Post prepaid electronic label which you can print or show at an Australia Post office to receive protective packaging for your device. You will not be charged for packaging or delivery.`,
      isopen: '0',
    },
    {
      Question: 'What does Asurion do when it receives my device? ',
      Answer: `Asurion will undertake a final assessment of your device to ensure your device qualifies for the Trade-in program and you received the correct trade-in value based on your answers to our questions. `,
      isopen: '0',
    },
    {
      Question: 'Why do I have to provide my ID?',
      Answer: `Asurion must collect a valid form of government issued photo identification from you, such as a driver licence, to comply with its legal obligations under the second-hand dealing laws and regulations. For information on how we protect your personal information, please refer to the <a style="color:
      #0BA8E6;text-decoration: none;cursor:
      pointer; "
      href="
      https://corporate.asurion.com.au/eng/privacy-policy">Privacy Policy</a>.  `,
      isopen: '0',
    },
    {
      Question: 'What is the Security Charge?',
      Answer: `You will need to provide valid payment card details to us when you trade in your eligible device. Your payment card details are required so Asurion can process payment of the Security Charge once you have completed your trade-in online. The Security Charge is the same amount as the trade-in credit you received and is like a deposit which is held by Asurion until your trade-in device is received and the final assessment conducted. `,
      isopen: '0',
    },
    {
      Question: 'Why have I only received a partial refund of the Security Charge?',
      Answer: `If Asurion’s final assessment of your trade-in device determines that the condition has been misrepresented, then the amount of the refund will be based on the revised value of your trade-in device.  `,
      isopen: '0',
    },
    {
      Question: 'Why have I been charged a late return fee?',
      Answer: `A late return fee of up to $50.00 will be charged to your payment card if you don’t return your device to Asurion within 14 days after presenting your Trade ID in store. `,
      isopen: '0',
    }
  ],
  imageName: 'MacBook Sample',
  selfServicePortal: {
    currentPage: 'GetEmailOtp',
    tradeid: '',
    emailid: '',
    returnLabel: '',
    otp: '',
    RedemptionOrder: '',
  },
  tradeDetails: {
    TradeStatus: '',
  },
  selectedPhoneStorage: null,
  SelectedPhoneDevice: null,
  selectedStorage: null,
  selectedCategory: null,
  selectedPhoneManufacturer: null,
  selectedPhoneSeries: null,
  SelectedDevice: null,
  selectedSeries: null,
  OTPResponse: null,
  ValidateResponse: null,
  SubCategory: null,
  sortedDeviceInfoArray: [],
  CustomerInfo: {},
  Eligibilty: null,
  isDeviceOn: null,
  serialno: null,
  termsAgreed: null,
  isFormComplete: null,
  isSubFormComplete: null,
  deviceName: null,
  PresignUrlResult: null,
  PresignUrlResult2: null,
  SearchAddressLine: null,
  PredectiveAddress: null,
  StandardizedAddress: null,
  StreetNo: null,
  StreetName: null,
  PostCode: null,
  Suburb: null,
  State: null,
  showRightAddressPopup: false,
  CountryCode: null,
  isAddressNotStandardized: true,
  doGetSuccess: false,
  QRcodePresignUrlResult: null,
  SendEmailResponse: null,
  selectedManufacturer: null,
  defaultAddress: null,
  QuoteDevice: null,
  QuotePhone: null,
  GoBackClicked: null,
  collectpaymentdata: null,
  surveyPages: {
    currentPage: 'Rating',
    tradeid: '',
    selectedRating: 10,
    customerCategory: 'PASSIVE',
    surveyQuestions: [],
  },
}

const reducer = (state = initialState, action) => {
  // console.log('prev state:', state, action)
  switch (action.type) {
    case types.SET_LOADING_INDICATOR:
      return { ...state, ...{ isLoading: true } }
    case types.UNSET_LOADING_INDICATOR:
      return { ...state, ...{ isLoading: false } }
    // case types.GET_RESOURCE_SUCCESS:
    //   return { ...state, ...{ resources: action.data } }
    case types.GET_MASTER_DATA_SUCCESS:
      let filteredDevices = action.data.DeviceDetails?.map(item => {
        if (!item.SimType?.includes('LVD')) {
          if (item.GwoPrice > 0) {
            return item
          }
        } else {
          return item
        }
      })?.filter(item => item)
      return { ...state, ...{ masterData: filteredDevices, resources: action.data.Resources, isBGBT: action.data.IsBGBT, maid: action.data.maid } }
    case types.SET_PROCESSOR:
      let model = action.payload?.value || action.payload
      return { ...state, ...{ selectedProcessor: model } }
    case types.SET_DEVICE_INFORMATION_OBJECT:
      let device = action.payload?.value || action.payload
      return { ...state, ...{ deviceinformation: device } }
    case types.SET_CATEGORY:
      let category = action.payload?.value || action.payload
      return { ...state, ...{ selectedCategory: category } }
    case types.SET_STORAGE:
      let storage = action.payload?.value || action.payload
      return { ...state, ...{ selectedStorage: storage } }
    case types.SET_PHONE_STORAGE:
      let phonestorage = action.payload?.value || action.payload
      return { ...state, ...{ selectedPhoneStorage: phonestorage } }
    case types.SET_STORAGE_ARRAY:
      let storageArray = action.payload?.value || action.payload
      return { ...state, ...{ sortedDeviceInfoArray: storageArray } }
    case types.SET_SUB_CATEGORY:
      return { ...state, ...{ SubCategory: action.data } }
    case types.SET_SELECTED_DEVICE:
      return { ...state, ...{ SelectedDevice: action.data } }
    case types.SET_SELECTED_PHONE_DEVICE:
      return { ...state, ...{ SelectedPhoneDevice: action.data } }
    case types.SET_FIRST_NAME:
      return { ...state, ...{ CustomerInfo: { ...state.CustomerInfo, FirstName: action.data } } }
    case types.SET_LAST_NAME:
      return { ...state, ...{ CustomerInfo: { ...state.CustomerInfo, LastName: action.data } } }
    case types.SET_EMAIL_ADDRESS:
      return { ...state, ...{ CustomerInfo: { ...state.CustomerInfo, EmailAddress: action.data } } }
    case types.SET_MOBILE_NO:
      return { ...state, ...{ CustomerInfo: { ...state.CustomerInfo, MobileNo: action.data } } }
    case types.SET_ADDRESS:
      return { ...state, ...{ CustomerInfo: { ...state.CustomerInfo, Address: action.data } } }
    case types.SEND_OTP_SUCCESS:
      return { ...state, ...{ OTPResponse: action.data.message } }
    case types.SEND_OTP_FAILURE:
      return { ...state, ...{ OTPResponse: 'OTP sending failed' } }
    case types.VALIDATE_OTP_SUCCESS:
      return { ...state, ...{ ValidateResponse: action.data.message } }
    case types.CLEAR_OTP_RESPONSE:
      return { ...state, ...{ ValidateResponse: null } }
    case types.VALIDATE_OTP_FAILURE:
      return { ...state, ...{ ValidateResponse: 'Incorrect OTP' } }
    case types.SET_ELIGIBILTY:
      return { ...state, ...{ Eligibilty: action.data } }
    case types.IS_DEVICE_ON:
      let object =
        state.DeviceType === 'Watch'
          ? state.deviceEvalutionObjectWearables
          : state.DeviceType === 'Surface'
            ? state.deviceEvalutionObject
            : state.DeviceType === 'LVD Laptop' ||
              state.DeviceType === 'LVD Tablet' ||
              state.DeviceType === 'LVD Watch' ||
              state.DeviceType === 'LVD Phone'
              ? state.deviceEvalutionObjectLvd
              : state.deviceEvalutionObject
      object[0].ans = action.data
      object[0].WO = action.data === 'Yes' ? 'GWO' : 'Ineligible'
      return { ...state, ...{ isDeviceOn: action.data } }
    case types.SET_SERIAL_NO:
      return { ...state, ...{ serialno: action.data } }
    case types.TERMS_AGREED:
      return { ...state, ...{ termsAgreed: action.data } }
    case types.SET_FORM_COMPLETE:
      return { ...state, ...{ isFormComplete: action.data } }
    case types.SET_QUESTIONANSWER_JSON:
      return { ...state, ...{ QuestionAnswersJson: action.data } }
    case types.SET_SURFACEQUESTIONANSWER_JSON:
      return { ...state, ...{ SurfaceQuestionAnswersJson: action.data } }
    case types.SET_LVDQUESTIONANSWER_JSON:
      return { ...state, ...{ LvdQuestionAnswersJson: action.data } }
    case types.SET_SUBFORM_COMPLETE:
      return { ...state, ...{ isSubFormComplete: action.data } }
    case types.SET_CREATEQUOTE_TESTS_ARRAY:
      return { ...state, ...{ CreateQuoteTestsArray: action.data } }
    case types.CREATE_QUOTE_SUCCESS:
      return { ...state, ...{ CreateQuoteResponse: action.data } }
    case types.DEVICE_NAME:
      return { ...state, ...{ deviceName: action.data } }
    case types.CLEAR_DATA:
      state[action.data] = null
      return { ...state }
    case types.GET_PRESIGN_URL_FOR_UPLOAD_SUCCESS:
      return { ...state, ...{ PresignUrlResult: action.data } }
    case types.SET_SEARCH_ADDRESS_LINE:
      return { ...state, ...{ SearchAddressLine: action.data } }
    case types.SET_PREDECTIVE_ADDRESS_SUCCESS:
      let PredectiveAddress = !action?.payload?.PickListAddress?.Moniker
        ? action?.payload?.PickListAddress
        : [action?.payload?.PickListAddress]
      return {
        ...state,
        ...{ PredectiveAddress: PredectiveAddress },
      }

    case types.GET_DO_GET_ADDRESS:
      return {
        ...state,
        ...{ StandardizedAddress: undefined },
      }
    case types.GET_DO_GET_ADDRESS_SUCCESS:
      let CorrectedAddress = action?.data?.AddressResult?.CorrectedAddress
      return {
        ...state,
        ...{
          StandardizedAddress: action.payload,
          StreetNo: CorrectedAddress?.AddressLine1,
          StreetName: CorrectedAddress?.AddressLine2,
          PostCode: CorrectedAddress?.PostalCode,
          Suburb: CorrectedAddress?.CityName?.toPascalCase(),
          State: CorrectedAddress?.StateProvinceCode,
          showRightAddressPopup: true,
          CountryCode: CorrectedAddress?.CountryCode,
          isAddressNotStandardized: false,
          doGetSuccess: true,
        },
      }

    case types.GET_PRESIGN_URL_2_SUCCESS:
      return { ...state, ...{ PresignUrlResult2: action.data } }
    case types.GET_QR_CODE_PRESIGN_URL__SUCCESS:
      return { ...state, ...{ QRcodePresignUrlResult: action.data } }
    case types.SEND_EMAIL_SUCCESS:
      return { ...state, ...{ SendEmailResponse: 'success' } }
    case types.SET_MANUFACTURER:
      let manufacturer = action.payload?.value || action.payload
      return { ...state, ...{ selectedManufacturer: manufacturer } }
    case types.SET_PHONE_MANUFACTURER:
      let phonemanufacturer = action.payload?.value || action.payload
      return { ...state, ...{ selectedPhoneManufacturer: phonemanufacturer } }
    case types.SET_FAQ_SUCCESS:
      return { ...state, ...{ FAQ: action.data } }
    case types.SET_SELECTED_ADDRESS_VALUE:
      return { ...state, ...{ defaultAddress: action.data } }
    case types.SET_IMAGE_NAME:
      return { ...state, ...{ imageName: action.data } }
    case types.SET_QUOTE_DEVICE:
      return { ...state, ...{ QuoteDevice: action.data } }
    case types.SET_QUOTE_PHONE:
      return { ...state, ...{ QuotePhone: action.data } }
    case types.GO_BACK_CLICKED:
      return { ...state, ...{ GoBackClicked: action.data } }
    case types.SET_SERIES:
      return { ...state, ...{ selectedSeries: action.data } }
    case types.SET_PHONE_SERIES:
      return { ...state, ...{ selectedPhoneSeries: action.data } }
    case types.UNSET_PHONE_SERIES:
      return { ...state, ...{ selectedPhoneSeries: null } }
    case types.DEVICE_TYPE:
      return {
        ...state,
        ...{
          DeviceType: action.data,
        },
      }
    case types.API_ERROR:
      return { ...state, ...{ isError: true, ErrorDetails: action.data } }

    case types.CREATE_INQUIRY_SUCCESS:
      return { ...state, ...{ InquiryData: action.data } }

    case types.SEND_OTP_SUCCESS:
      return { ...state, ...{ sendOtpResponse: action.data.OTPResponse, ErrorDetails: '' } }

    case types.SEND_OTP_FAILURE:
      return {
        ...state,
        ...{ sendOtpResponse: action.data, ErrorDetails: action.data?.response?.data?.message },
      }

    case types.VERIFY_OTP_SUCCESS:
      return { ...state, ...{ verifyOtpResponse: action.data, ErrorDetails: '' } }

    case types.SET_SUBSCRIPTION_NUMBER: {
      const keyNmame = action.payload.searchinputName
      const keyValue = action.payload.data
      let obj = {}
      if (keyNmame === 'subscriptionNumber') {
        obj = {
          subscriptionNumber: keyValue,
          IMEI: undefined,
          upgradeNumber: undefined,
        }
      } else if (keyNmame === 'IMEI') {
        obj = {
          IMEI: keyValue,
          upgradeNumber: undefined,
          subscriptionNumber: undefined,
        }
      } else if (keyNmame === 'upgradeNumber') {
        obj = {
          upgradeNumber: keyValue,
          IMEI: undefined,
          subscriptionNumber: undefined,
        }
      }
      return { ...state, ...{ ...obj, InquiryData: undefined } }
    }
    case types.SET_HOW_CAN_I_HELP:
      return { ...state, ...{ howCaniHelp: action.data, InquiryData: undefined } }

    case types.SET_EMAIL_ADDRESS:
      return { ...state, ...{ emailAddress: action.data, InquiryData: undefined } }

    case types.SET_NAME:
      return { ...state, ...{ name: action.data, InquiryData: undefined } }

    case types.SET_CONTACT_NUMBER:
      return { ...state, ...{ contactNumber: action.data, InquiryData: undefined } }

    case types.SET_NOTE:
      return { ...state, ...{ note: action.data, InquiryData: undefined } }
    case types.SET_TRADE_STATUS:
      return {
        ...state,
        ...{ tradeStatus: action.data },
      }

    case types.SET_SEARCH_INPUT:
      return {
        ...state,
        ...{
          searchInput: action.data,
          IMEI: '',
          upgradeNumber: '',
          subscriptionNumber: '',
          InquiryData: undefined,
        },
      }

    case types.SET_SSP_EMAIL_ID:
      return {
        ...state,
        selfServicePortal: {
          ...state.selfServicePortal,
          emailid: action.data,
        },
      }

    case types.SET_SSP_TRADE_ID:
      return {
        ...state,
        selfServicePortal: {
          ...state.selfServicePortal,
          tradeid: action.data,
        },
      }
    case types.SET_SSP_PAGE:
      return {
        ...state,
        selfServicePortal: {
          ...state.selfServicePortal,
          currentPage: action.data,
        },
      }

    case types.SET_SSP_OTP:
      return {
        ...state,
        selfServicePortal: {
          ...state.selfServicePortal,
          otp: action.data,
        },
      }
    case types.SET_TRADE_DETAILS_SUCCESS:
      return {
        ...state,
        tradeDetails: { ...action.data.TradeDetails },
        selfServicePortal: {
          ...state.selfServicePortal,
          RedemptionOrder: action.data.TradeDetails.RedemptionOrder,
        },
      }
    case types.SET_TRADE_STATUS_SUCCESS:
      return {
        ...state,
        cancelMessage: action.data.CancelQuoteResponse.message,
      }
    case types.SET_GENERATE_LABEL:
      return {
        ...state,
        selfServicePortal: {
          ...state.selfServicePortal,
          returnLabel: action.data.ParcelLabelResponse,
        },
      }
    case types.SET_CHAT_WINDOW:
      return {
        ...state,
        chatBot: {
          ...state.chatBot,
          isOpen: action.data,
        },
      }
    case types.SET_CHAT_IS_LOGGED_IN:
      return {
        ...state,
        chatBot: {
          ...state.chatBot,
          isLoggedIn: action.data,
        },
      }
    case types.GET_IMAGE_SUCCESS:
      return {
        ...state,
        GetDAXImageResponse: action.data.GetDAXImageResponse,
      }
    case types.SET_SHIPPING_LABEL_SUCCESS:
      return {
        ...state,
        shipmentLabelResponse: { ...action.data.ShipQuoteResponse },
      }
    case types.SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, isPayoutDetailsSaved: true }
    case types.SAVE_PAYOUT_DETAILS_FAILUER:
      return { ...state, savepayoutDetailsError: true }
    case types.SUBMIT_PAYOUT_SUCCESS:
      return { ...state, isPayoutSuccess: true }
    case types.SUBMIT_PAYOUT_FAILURE:
      return { ...state, savepayoutDetailsError: true }
    case types.CLEAR_PAYOUT_DETAILS:
      return { ...state, isPayoutDetailsSaved: undefined, savepayoutDetailsError: undefined, isPayoutSuccess: undefined }

    case types.SEND_SS_OTP_REQUEST_SUCCESS:
      return { ...state, ...{ sendSSOtpResponse: action.data } }
    case types.SEND_SS_OTP_REQUEST_FAILURE:
      return { ...state, ...{ sendSSOtpResponse: '' } }
    case types.VERIFY_SS_OTP_REQUEST_SUCCESS:
      return { ...state, ...{ SSValidateResponse: action.data } }
    case types.VERIFY_SS_OTP_REQUEST_FAILURE:
      return { ...state, ...{ SSValidateResponse: action.data } }
    case types.Get_ALL_SS_TRADES_SUCCESS:
      return {
        ...state,
        SStradeDetails: { ...action.data.TradeDetails },
        selfServicePortal: {
          ...state.selfServicePortal,
          RedemptionOrder: action.data.TradeDetails.RedemptionOrder,
        },
      }
    case types.GET_SS_TRADE_DETAILS_SUCCESS:
      return { ...state, ...{ getSSTradeDetails: action.data } }
    case types.GET_SS_TRADE_DETAILS_FAILURE:
      return { ...state, ...{ getSSTradeDetails: '' } }
    case types.SET_PCITOKEN:
      return { ...state, ...{ token: action.data } }
    case types.SET_COLLECT_PAYMENT:
      return { ...state, ...{ collectpaymentdata: action.data } }

    case types.CANCEL_SS_TRADE_DETAILS_SUCCESS:
      return { ...state, ...{ cancelSSTrade: action.data } }
    case types.CANCEL_SS_TRADE_DETAILS_FAILURE:
      return { ...state, ...{ cancelSSTrade: '' } }

    case types.RETURN_SS_LABEL_SUCCESS:
      return { ...state, ...{ returnSSLabelTrade: action.data } }
    case types.RETURN_SS_LABEL_FAILURE:
      return { ...state, ...{ returnSSLabelTrade: '' } }

    case types.SET_DATA_SET:
      return { ...state, ...{ DataSet: action.data } }

    case types.CLEAR_STATE:
      return {
        ...state, ...initialState
      };
    case types.SET_PAYMENT_METHOD:
      return { ...state, ...{ paymentMethod: action.data } }

    case types.GET_SURVEY_QUESTIONS_SUCCESS:
      return { ...state, surveyPages: { ...state.surveyPages, surveyQuestions: action.data?.GetSurveyQuestionsResponse?.SurveyQuestions } }

    case types.SET_SURVEY_PAGE:
      return {
        ...state,
        surveyPages: {
          ...state.surveyPages,
          currentPage: action.data,
        },
      }
    case types.SET_SELECTED_RATING:
      return { ...state, surveyPages: { ...state.surveyPages, selectedRating: action.data } }
    case types.SET_CUSTOMER_CATEGORY:
      return { ...state, surveyPages: { ...state.surveyPages, customerCategory: action.data } }
    case types.SET_SURVEY_TRADE_ID:
      return { ...state, surveyPages: { ...state.surveyPages, tradeid: action.data } }
    default:
      return { ...state }
  }
}

export { initialState, reducer }
