import React from 'react'

const Number9 = ({ circleColour, numberColour, handleClick }) => (

<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
<rect x="1" y="1" width="24" height="25" rx="12" fill={circleColour}/>
<rect x="1" y="1" width="24" height="25" rx="12" stroke="#0C0E11"/>
<path d="M10.86 17.5H9.67698V9.791L7.36298 10.649L7.02498 9.648L9.75498 8.569H10.86V17.5ZM17.55 16.265C17.8013 16.0743 18.0007 15.8187 18.148 15.498C18.304 15.1687 18.4123 14.7917 18.473 14.367C18.5337 13.9423 18.564 13.5003 18.564 13.041C18.564 12.573 18.5337 12.1267 18.473 11.702C18.4123 11.2773 18.304 10.9047 18.148 10.584C18.0007 10.2547 17.8013 9.99467 17.55 9.804C17.3073 9.61333 16.9997 9.518 16.627 9.518C16.2543 9.518 15.9423 9.61333 15.691 9.804C15.4483 9.99467 15.249 10.2547 15.093 10.584C14.9457 10.9047 14.8417 11.2773 14.781 11.702C14.7203 12.1267 14.69 12.573 14.69 13.041C14.69 13.5003 14.7203 13.9423 14.781 14.367C14.8417 14.7917 14.9457 15.1687 15.093 15.498C15.249 15.8187 15.4483 16.0743 15.691 16.265C15.9423 16.4557 16.2543 16.551 16.627 16.551C16.9997 16.551 17.3073 16.4557 17.55 16.265ZM19.071 16.239C18.8197 16.655 18.4947 16.993 18.096 17.253C17.6973 17.5043 17.2077 17.63 16.627 17.63C16.0463 17.63 15.5567 17.5043 15.158 17.253C14.7593 16.993 14.4343 16.655 14.183 16.239C13.9317 15.8143 13.7497 15.3247 13.637 14.77C13.5243 14.2153 13.468 13.639 13.468 13.041C13.468 12.443 13.5243 11.8667 13.637 11.312C13.7497 10.7573 13.9317 10.2677 14.183 9.843C14.4343 9.41833 14.7593 9.08033 15.158 8.829C15.5567 8.569 16.0463 8.439 16.627 8.439C17.2077 8.439 17.6973 8.569 18.096 8.829C18.4947 9.08033 18.8197 9.41833 19.071 9.843C19.3223 10.2677 19.5043 10.7573 19.617 11.312C19.7297 11.8667 19.786 12.443 19.786 13.041C19.786 13.639 19.7297 14.2153 19.617 14.77C19.5043 15.3247 19.3223 15.8143 19.071 16.239Z" fill={numberColour}/>
</svg>
)

export default Number9