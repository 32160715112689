import React from 'react'

const Number3 = ({ circleColour, numberColour, handleClick }) => (
<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
<rect x="1" y="1" width="24" height="25" rx="12" fill={circleColour}/>
<rect x="1" y="1" width="24" height="25" rx="12" stroke="#0C0E11"/>
<path d="M15.5765 17.5H10.0775C10.0602 17.3873 10.0472 17.279 10.0385 17.175C10.0298 17.0623 10.0255 16.9583 10.0255 16.863C10.0255 16.3863 10.0992 15.966 10.2465 15.602C10.4025 15.2293 10.6018 14.9 10.8445 14.614C11.0958 14.3193 11.3688 14.0593 11.6635 13.834C11.9668 13.6 12.2702 13.379 12.5735 13.171C12.8162 13.0063 13.0502 12.846 13.2755 12.69C13.5008 12.5253 13.6958 12.3563 13.8605 12.183C14.0338 12.001 14.1682 11.8103 14.2635 11.611C14.3675 11.4117 14.4195 11.1907 14.4195 10.948C14.4195 10.5233 14.2678 10.181 13.9645 9.921C13.6698 9.65233 13.2798 9.518 12.7945 9.518C12.4132 9.518 12.0492 9.60033 11.7025 9.765C11.3558 9.92967 11.0222 10.1593 10.7015 10.454L10.0905 9.583C10.5152 9.18433 10.9572 8.894 11.4165 8.712C11.8758 8.53 12.3438 8.439 12.8205 8.439C13.2192 8.439 13.5875 8.49967 13.9255 8.621C14.2722 8.73367 14.5712 8.90267 14.8225 9.128C15.0738 9.34467 15.2732 9.609 15.4205 9.921C15.5678 10.2243 15.6415 10.571 15.6415 10.961C15.6415 11.325 15.5765 11.65 15.4465 11.936C15.3252 12.222 15.1605 12.482 14.9525 12.716C14.7445 12.95 14.5062 13.1667 14.2375 13.366C13.9775 13.5567 13.7132 13.7473 13.4445 13.938C13.2018 14.1113 12.9592 14.2847 12.7165 14.458C12.4825 14.6313 12.2658 14.8177 12.0665 15.017C11.8758 15.2077 11.7112 15.42 11.5725 15.654C11.4338 15.888 11.3385 16.148 11.2865 16.434H15.5765V17.5Z" fill={numberColour}/>
</svg>
)

export default Number3
