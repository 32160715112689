import React from 'react'

const Number2 = ({ circleColour, numberColour, handleClick }) => (
<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
<rect x="1" y="1" width="24" height="25" rx="12" fill={circleColour}/>
<rect x="1" y="1" width="24" height="25" rx="12" stroke="#0C0E11"/>
<path d="M14.4845 17.5H13.3015V9.791L10.9875 10.649L10.6495 9.648L13.3795 8.569H14.4845V17.5Z" fill={numberColour}/>
</svg>  
)

export default Number2
