import React from 'react'

const Number8 = ({ circleColour, numberColour, handleClick }) => (

<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
<rect x="1" y="1" width="24" height="25" rx="12" fill={circleColour}/>
<rect x="1" y="1" width="24" height="25" rx="12" stroke="#0C0E11"/>
<path d="M10.4545 15.836C10.7492 16.0093 11.0742 16.174 11.4295 16.33C11.7848 16.4773 12.1618 16.551 12.5605 16.551C12.9592 16.551 13.2928 16.4643 13.5615 16.291C13.8388 16.109 14.0642 15.8663 14.2375 15.563C14.4108 15.2597 14.5365 14.9087 14.6145 14.51C14.6925 14.1113 14.7402 13.6867 14.7575 13.236C14.4628 13.522 14.1335 13.7517 13.7695 13.925C13.4055 14.0983 13.0112 14.185 12.5865 14.185C12.2052 14.185 11.8498 14.1157 11.5205 13.977C11.1998 13.8383 10.9182 13.6433 10.6755 13.392C10.4415 13.1407 10.2552 12.8417 10.1165 12.495C9.97782 12.1483 9.90849 11.767 9.90849 11.351C9.90849 10.961 9.96915 10.5927 10.0905 10.246C10.2205 9.89067 10.4068 9.583 10.6495 9.323C10.9008 9.05433 11.2042 8.842 11.5595 8.686C11.9235 8.52133 12.3395 8.439 12.8075 8.439C13.3795 8.439 13.8648 8.56033 14.2635 8.803C14.6622 9.04567 14.9872 9.375 15.2385 9.791C15.4898 10.1983 15.6718 10.675 15.7845 11.221C15.8972 11.7583 15.9535 12.326 15.9535 12.924C15.9535 13.496 15.8972 14.0637 15.7845 14.627C15.6805 15.1903 15.4985 15.693 15.2385 16.135C14.9785 16.577 14.6318 16.9367 14.1985 17.214C13.7652 17.4913 13.2278 17.63 12.5865 17.63C12.1358 17.63 11.6895 17.5607 11.2475 17.422C10.8142 17.2747 10.4068 17.0667 10.0255 16.798L10.4545 15.836ZM13.8605 12.859C14.1898 12.6943 14.4758 12.482 14.7185 12.222C14.6752 11.7107 14.5885 11.2817 14.4585 10.935C14.3285 10.5883 14.1725 10.311 13.9905 10.103C13.8172 9.895 13.6222 9.74767 13.4055 9.661C13.1888 9.56567 12.9678 9.518 12.7425 9.518C12.4825 9.518 12.2528 9.56567 12.0535 9.661C11.8542 9.75633 11.6852 9.89067 11.5465 10.064C11.4165 10.2287 11.3125 10.4237 11.2345 10.649C11.1652 10.8657 11.1305 11.0997 11.1305 11.351C11.1305 11.9143 11.2865 12.3477 11.5985 12.651C11.9192 12.9543 12.3265 13.106 12.8205 13.106C13.1932 13.106 13.5398 13.0237 13.8605 12.859Z" fill={numberColour}/>
</svg>

)

export default Number8
