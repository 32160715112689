import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Button from '../shared/Button'
// import Checkbox from '../shared/Checkbox'
import { useActions } from '../../Action'
import { store } from '../../Store'
import { useHistory } from 'react-router-dom'

const CancelledTrade = ({ handleCancel, closePopup }) => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const [allowCancel, setAllowCancel] = useState(false)
  
  const history = useHistory()
  const navigateBack = () => {
    actions.clearData('getSSTradeDetails')
    actions.clearData('sendSSOtpResponse')
    actions.clearData('SSValidateResponse')
    history.replace('/')
  }


  return (
    <div className="w-full">
      <div className="flex flex-col mt-8 mb-6">
        <Button 
          label="Go Back" 
          className="w-56 mx-auto" 
          disabled={false} 
          type="" 
          textClass="px-12 text-black" 
          borderClass="border border-gray-400 rounded-lg"
          //   onClick={() => navigateBack()}
          onClick={closePopup}  
        />
      </div>
    </div>
  )
}

CancelledTrade.defaultProps = {
  handleCancel: undefined,
  closePopup: undefined,
}

CancelledTrade.propTypes = {
  closePopup: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default CancelledTrade
