import React, { useContext } from 'react'
import TradeDetails from './TradeDetails'
import RefundTrade from './RefundTrade'
import GetEmailOtp from './GetEmailOtp'
import VerifyOtp from './VerifyOtp'
import { store } from '../../Store'
import { ServicePortalPages } from '../../ActionTypes'
import HeaderLogo from '../shared/Header'
// import ChatButton from '../chatSupport/ChatButton'
// import ChatContainer from '../chatSupport/ChatContainer'

const SelfServicePortal = () => {
  const { state,  } = useContext(store)
  const {
    selfServicePortal: { currentPage },
    // chatBot: { isOpen },
  } = state

  return (
    <>
      <div className="relative w-700 mx-auto md:w-auto md:mx-m15">
        {/* <HeaderLogo /> */}
        {currentPage === ServicePortalPages.TradeDetails && <TradeDetails />}
        {currentPage === ServicePortalPages.Refund && <RefundTrade />} 
        {currentPage === ServicePortalPages.GetEmailOtp && <GetEmailOtp setIsssp={false}/>}
        {currentPage === ServicePortalPages.VerifyOtp && <VerifyOtp />}
      </div>
      {(currentPage === ServicePortalPages.GetEmailOtp && <div className='absolute bottom-10 left-0 right-0'>
        <p className="font-SamsungOne-400 text-sm text-center">
        For enquiries about the Asurion Trade-In Program, please<a href={"https://corporate.asurion.com.au/contact-us"} target="_blank" rel="noreferrer" className='underline ml-1'>contact us</a>.
        </p>
      </div>
      )}
      {/* <ChatButton />
      {isOpen && <ChatContainer />} */}
    </>
  )
}

export default SelfServicePortal
