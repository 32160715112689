import React, { useState, useContext, useEffect } from 'react'
import { store } from '../../Store'
import { useActions } from '../../Action'
import Button from '../shared/Button'
import { ServicePortalPages } from '../../ActionTypes'
import PageHeader from '../shared/PageHeader'
import Text from '../shared/Text'
import Hyperlink from '../shared/hypertext'
import OtpLogo from '../../assets/selfserve-assets/otp.svg'
import Input from '../shared/input'
import IconLogo from '../../assets/selfserve-assets/TradeNSave.svg'
import Timer from './timer';
import { useHistory } from 'react-router-dom'
import { getMaskedEmail } from '../../utils/helper'

const VerifyOtp = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { selfServicePortal, SSValidateResponse, sendSSOtpResponse, getSSTradeDetails } = state
  const { emailid, otp, tradeid } = selfServicePortal
  const OTP_EXPIRE_MINUTES = 5;
  const history = useHistory()

  const dtt = new Date();
  dtt.setMinutes(dtt.getMinutes() + OTP_EXPIRE_MINUTES);

  const [timer, setTimer] = useState({ Timer: dtt });
  const [disabledResend, setDisabledResend] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);

  const callback = (value) => {
    if (value === '00:00'){
      setOtpExpired(true);
    }
    else if (value <= '04:00') {
      setDisabledResend(false);
      setOtpExpired(false);
    } 
    else {
      setDisabledResend(true);
    }
  };

  const handleOtpChange = (value) => {
    const val = value.trim().replaceAll(' ', '')
    const otpRegex = /^[0-9\b]{0,6}$/
    if (otpRegex.test(val)) {
      actions.setSSPOtp(val)
    }
  }

  const getTradeDetails = () => {
    const payload = {
      tradeId: sendSSOtpResponse?.TradeId,
    }

    const gettradepayload ={
      payload: payload,
      fromOtp: true
    }
    actions.getSSTradesDetails(gettradepayload)
  }

  const verifyOtpRequest = () => {
    const payload = {
      pin: otp,
      email: emailid,
    }
    actions.verifySSOtp(payload)
  }
  useEffect(() => {
    if (SSValidateResponse?.message === 'success') {
      getTradeDetails();
    }
  }, [SSValidateResponse]);

  const resetTimer = () => {
    const newDtt = new Date();
    newDtt.setMinutes(newDtt.getMinutes() + OTP_EXPIRE_MINUTES);
    setTimer({ Timer: newDtt });
    setDisabledResend(true);
    setOtpExpired(false) 
  };

  const sendOtpRequest = () => {
    const payload = {
      email: emailid,
      tradeId: tradeid,
    }
    actions.sendSSOTPMail(payload)
    resetTimer();
  }

  return (
    <div className="flex flex-col justify-center items-center w-468 md:w-auto mx-auto">
      <div className="px-p20 pt-10 mx-auto mb-10">
        <IconLogo className="" />
      </div>
      <PageHeader label="Validate Email Address" />
      <Text text="A code has been sent to your email address. Please enter the code that was sent to your email address." textClass="text-left mt-10" />
      <Text text="Email" textClass="text-center mt-9" />
      <Text text={getMaskedEmail(emailid)} textClass="text-center text-blue-500 text-lg mt-2 mb-6" />
      <div className="right-content form-row min-w-395 max-w-495 mb-5 mx-auto">
        <Input type="text" name="otp" label="Enter code" placeholder="" value={otp} onChange={(value) => handleOtpChange(value)} className="border-b"/>
      </div>
      <Timer parentCallback={(value) => callback(value)} state={timer} />
      <Text text="For your protection, your code will expire in 5 minutes." textClass="text-[12px] mt-5 mb-10"/>
      {SSValidateResponse?.message?.toLowerCase() === "wrong" && !otpExpired && <Text text="Entered OTP is incorrect. Please try again." textClass="font-SamsungOne-400 text-[12px] text-red-600 text-center mb-10" />}
      {otpExpired && <Text text="OTP Expired. Please resend the code." textClass="font-SamsungOne-400 text-[12px] text-red-600 text-center mb-10" />}
      <div className='rounded-lg mx-auto w-300 mb-6'>
        <Button
          label="Continue"
          disabled={!(otp && otp.length >= 6) || otpExpired}
          marginClass="text-white border rounded-md"
          borderClass="rounded-md"
          bgColor="bg-HN-Purple-Dark"
          disabledBgColor="bg-gray-400"
          onClick={verifyOtpRequest}
        />
      </div>
      
      <div className="flex flex-row justify-between w-468 md:w-auto md:gap-28">
        <Hyperlink linkText="Resend Code" textClass="" containerClass="w-full md:w-auto text-left" onClick={sendOtpRequest} disabled={disabledResend}/>
        <Hyperlink linkText="Select a new email" textClass="" containerClass="w-full md:w-auto text-right" onClick={() => location.reload()} />
      </div>
      <div className="bg-gray-200 flex flex-row w-468 md:w-auto px-4 py-4 mt-8 rounded-lg mb-20 items-center">
        <div className=''><OtpLogo className="" /></div>
        <div className="text-sm pl-2">
          <span>*Once you've clicked on the</span>
          <span className="font-bold"> Resend Code</span>
          <span> button, we will email it to you. Please check your spam/junk folders. If the problem persists please check the email address you entered.</span>
        </div>
      </div>
      {/* <div className='flex text-center items-center justify-center pt-4 pb-8'>
        <span className="text-HN-Purple-Dark underline cursor-pointer" onClick={() => history.replace('/')}>
          Go back
        </span>{' '}
      </div> */}
    </div>
  )
}

export default VerifyOtp

/* eslint-disable no-unused-vars */
